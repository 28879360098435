export type RequestConfig = {
    method: 'GET' | 'POST' | 'OPTIONS' | 'DELETE' | 'PUT' | 'PATCH';
    url: string;
    responseType: 'text' | 'blob' | 'json' | 'null',
};

type SuccessResponseData<T> = {
    isSuccess: true;
    body: T;
    error: null;
    responseType: string;
};

type ErrorResponseData = {
    isSuccess: false;
    body: null;
    error: string;
    responseType: string;
};

export type ResponseData<T> = SuccessResponseData<T> | ErrorResponseData;

const BASE_API_URL: string = process.env.REACT_APP_BACKEND_ENDPOINT || '';

export const makeRequest = async <T>(
    method: 'GET' | 'POST' | 'OPTIONS' | 'DELETE' | 'PUT' | 'PATCH',
    path: string,
    token: string,
    body: any = null,
    responseType: 'text' | 'blob' | 'json' | 'null' = 'json',
    isJson: boolean = true
): Promise<ResponseData<T>> => {
    const headers: HeadersInit = { 'Authorization': `Bearer ${token}` };

    if (isJson && body) {
        headers['Content-Type'] = 'application/json';
        body = JSON.stringify(body);
    }

    const requestParams: RequestInit = {
        method: method,
        headers: headers,
        ...(body && { body: body })
    };

    const url = `${BASE_API_URL}${path}`;

    try {
        const response = await fetch(url, requestParams);

        if (response.ok) {
            let responseBody: any;
            switch (responseType) {
                case 'text':
                    responseBody = await response.text();
                    break;
                case 'blob':
                    responseBody = await response.blob();
                    break;
                default:
                    responseBody = await response.json();
            }
            return { isSuccess: true, body: responseBody, error: null, responseType };
        } else {
            const error_msg = await response.text();
            console.error(error_msg);
            return { isSuccess: false, body: null, error: error_msg, responseType };
        }
    } catch (e) {
        const error_msg = `Network error. Unable to send request to ${url}`;
        console.error(error_msg, e);
        return { isSuccess: false, body: null, error: error_msg, responseType };
    }
};

export default { makeRequest };