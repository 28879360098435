import React, { useState } from 'react';
import { SpeedDialIcon, SpeedDialAction, SpeedDial, Box} from '@mui/material';
import BlurBox from './BlurBox';

export default function SpeedNav({ actions, }) {
  const [content, setContent] = useState(null);

  const handleClick = (action) => {
    if (action.onClick) {
      action.onClick();
    }
    setContent(action.content);
  };

  const handleClose = () => {
    setContent(null);
  };

  return (
    <>
      {content && (
        <BlurBox onClose={handleClose}>
          {React.cloneElement(content, { onClose: handleClose })}
        </BlurBox>
      )}
      <Box sx={{ 
        position: 'fixed',
        bottom: 16, 
        right: 16,
        transform: 'translateZ(0px)',
        zIndex: (theme) => theme.zIndex.speedDial,
      }}>
        <SpeedDial
          ariaLabel="SpeedDial"
          icon={<SpeedDialIcon />}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={() => handleClick(action)}
            />
          ))}
        </SpeedDial>
      </Box>
    </>
  );
}