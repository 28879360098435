import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { useTheme } from '@mui/material/styles';

const FileInput = ({dragHeight, listHeight, uploadedFiles, setUploadedFiles}) => {
  const theme = useTheme();

  const handleFileChange = (event) => {
    const fileList = event.target.files;
    const newFiles = Array.from(fileList);
    setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    console.log('Uploaded files:', newFiles);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const fileList = event.dataTransfer.files;
    const newFiles = Array.from(fileList);
    setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleDelete = (index) => {
    setUploadedFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };

  const getFileTypeIcon = (fileName) => {
    const fileExtension = fileName.split('.').pop().toLowerCase();
    if (fileExtension === 'pdf') {
      return <PictureAsPdfRoundedIcon sx={{ fontSize: 25, color: theme.palette.primary.main }} />;
    }
    if (fileExtension === 'doc' || fileExtension === 'docx') {
      return <TextSnippetIcon sx={{ fontSize: 25, color: theme.palette.primary.main }} />;
    } else {
      return <FilePresentIcon sx={{ fontSize: 25, color: theme.palette.primary.main }} />;
    }
  };


  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Paper variant="outlined" sx={{ padding: '16px' }}>
        <Box
          sx={{
            border: '1px dashed #ccc',
            padding: '16px',
            borderRadius: '4px',
            textAlign: 'center',
            marginBottom: '16px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: dragHeight,
          }}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <Typography variant="body1" mb={1} sx={{color: theme.palette.text.darkSurfaceWhite}}>
            Drag and drop files here
          </Typography>
          <input
            accept="*"
            id="file-input"
            multiple
            type="file"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <label htmlFor="file-input">
            <Button variant="contained" component="span">
              Add Files
            </Button>
          </label>
        </Box>
        <Box
          sx={{
            height: listHeight,
            overflow: 'auto',
            backgroundColor: theme.palette.surfaces.darker,
            '&::-webkit-scrollbar': {
              width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.scrollbar.thumb,
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: theme.palette.scrollbar.track,
            },
          }}
        >
          {uploadedFiles.length > 0 ? (
            <List>
              {uploadedFiles.map((file, index) => (
                <ListItem key={index}>
                  <ListItemAvatar>{getFileTypeIcon(file.name)}</ListItemAvatar>
                  <ListItemText primary={<Typography noWrap>{file.name}</Typography>} />
                  <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(index)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography variant="body2" sx={{ textAlign: 'center', py: 2, color: theme.palette.text.darkSurfaceWhite }}>
              No documents submitted
            </Typography>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default FileInput;