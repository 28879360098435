import { SettingsFormType } from "./plotting_backend";

export interface ThemeType {
    theme_name: string;
    colors: string[];
    font_family: string;
    settings?: {group: keyof SettingsFormType, key: keyof SettingsFormType[keyof SettingsFormType], value: any}[];
}

export const plottingThemes: { [key: string]: ThemeType } = {
    "ReSolve": {
        theme_name: "ReSolve",
        colors: [
            "#00488d", "#fbba00", "#000000", "#89d2ff", "#6f4596", 
            "#d3d800", "#81aec6", "#f29400", "#b3d852", "#00abff",
            "#7e768c", "#e6c245", "#63cc77", "#293645", "#88949e"
        ],
        font_family: "Helvetica Neue",
        settings: [
            {group: "axis_settings", key: "axis_color", value: "#023858"},
        ],
    },
    "Return-Stacked": {
        theme_name: "Return-Stacked",
        colors: [
            '#323a46', '#14cfa6', '#3a6a9c', '#7da5ce', '#ffe885', 
            '#ebe96a', '#0a0a0b', '#0c7c64', '#23405e', '#366390',
            '#3bb823', '#B4B218', '#3F3B47', '#31EBC2', '#5287BF', 
            '#97B7D8', '#AAED9D', '#EFED88', '#0E0D0F', '#10A685'
        ],
        font_family: "DM Sans"
    }
}