import { makeRequest, RequestConfig } from "../../../common/utils/requestUtils";

const port = process.env.REACT_APP_PLOTTING_APP_PORT;

const requestConfigs: Record<string, RequestConfig> = {
  getPlotFile: {
    method: 'POST',
    url: `:${port}/plotting/plot`,
    responseType: 'text'
  },
  downloadPlotFile: {
    method: 'POST',
    url: `:${port}/plotting/plot/download/`,
    responseType: 'blob'
  },
  getDataOptions: {
    method: 'GET',
    url: `:${port}/plotting/data`,
    responseType: 'json'
  }
};

export async function getPlotFile(plotForm: any, token: string): Promise<string> {
  const config = requestConfigs.getPlotFile;
  const response = await makeRequest<string>(config.method, config.url, token, plotForm, config.responseType);
  if (response.isSuccess) {
      return response.body;
  } else {
      alert("Sorry, there was an error fetching the plot.\nRefresh the page and try again.");
      throw new Error("Failed to fetch plot");
  }
}

export async function downloadPlotFile(plotForm: any, fileType: string, token: string) {
  try {
    const config = requestConfigs.downloadPlotFile; 
    const response = await makeRequest<Blob>(config.method, `${config.url}${fileType}`, token, plotForm, config.responseType);
    if (response.isSuccess) {
        const plotFile = response.body;
        const downloadUrl = window.URL.createObjectURL(plotFile);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `plot.${fileType}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
    } else {
        alert("Sorry, there was an error fetching the plot.\nRefresh the page and try again.");
        throw new Error("Failed to download plot");
    }
  } catch (error) {
    console.error("An error occurred while downloading the plot file:", error);
    alert("Sorry, an unexpected error occurred.\nRefresh the page and try again.");
    throw error;
  }
}

export async function getDataOptions(token: string): Promise<string[]> {
  const config = requestConfigs.getDataOptions;
  const response = await makeRequest<string[]>(config.method, config.url, token, undefined, config.responseType);
  if (response.isSuccess) {
      return response.body.sort();
  } else {
      alert("Failed to load data options :(\nBlame Kiegan");
      throw new Error("Failed to load data options");
  }
}