import { makeRequest, RequestConfig } from "../../../common/utils/requestUtils";
import RDoc from "../types/RDoc";

const port = process.env.REACT_APP_DOCUMENT_MANAGER_PORT;

const requestConfigs: Record<string, RequestConfig> = {
  fetchDocuments: {
    method: 'OPTIONS',
    url: `:${port}/documents`,
    responseType: 'json'
  },
  saveFile: {
    method: 'POST',
    url: `:${port}/file`,
    responseType: 'null',
  },
  getFile: {
    method: 'GET',
    url: `:${port}/file`, 
    responseType: 'blob',
  },
  copyDocument: {
    method: 'PUT',
    url: `:${port}/file`,
    responseType: 'null',
  },
  createDocument: {
    method: 'PUT',
    url: `:${port}/document`,
    responseType: 'json'
  },
  renameDocument: {
    method: 'PATCH',
    url: `:${port}/document`,
    responseType: 'json'
  },
  deleteDocument: {
    method: 'DELETE',
    url: `:${port}/document`,
    responseType: 'json'
  },
};
// // File Management Router



// save_file endpoint
export const saveFile = async (file: File, id: string, filename: string, token: string) => {
    const formData = new FormData();
    formData.append("file", file);
    
    requestConfigs.saveFile.url = `${requestConfigs.saveFile.url}/${id}/${filename}`;

    const config = requestConfigs.saveFile;
    const response = await makeRequest<null>(config.method, config.url, token, formData, config.responseType, false);
    
    if (!response.isSuccess) {
        alert("Failed to save file");
        throw new Error("Failed to save file");
    }
}

// get_file endpoint
export const getFile = async (id: string, filename: string, token: string): Promise<File> => {
    const fileUrl = `${requestConfigs.getFile.url}/${id}/${filename}`;
    
    const config = { ...requestConfigs.getFile, url: fileUrl };
    const response = await makeRequest<Blob>(config.method, config.url, token, null, config.responseType);
    
    if (response.isSuccess) {
      let blob = response.body;
      let file = new File([blob], filename, { type: blob.type });
      return file;
    } else {
      alert("Failed to get file");
      throw new Error("Failed to get file");
    }
};

// copy_document endpoint
export const copyDocument = async (id: string, new_document_id: string, token: string) => {
  const config = { ...requestConfigs.copyDocument, url: `${requestConfigs.copyDocument.url}/${id}/${new_document_id}` };
  const response = await makeRequest<null>(config.method, config.url, token, null, config.responseType);
  if (!response.isSuccess) {
    alert("Failed to copy document");
    throw new Error("Failed to copy document");
  }
}


// // Metadata Router

// list_documents endpoint
export const listDocuments = async (token: string): Promise<RDoc[]> => {
  const config = requestConfigs.fetchDocuments;
  const response = await makeRequest<any[]>(config.method, config.url, token, null, config.responseType);

  if (response.isSuccess) {
      const rDocs = response.body.map(RDoc.fromJSON);
      return rDocs
  } else {
      alert("Failed to fetch documents");
      throw new Error("Failed to fetch documents");
  }
};

// create_document endpoint
export const createDocument = async (newDocument: RDoc, token: string) => {
  const config = requestConfigs.createDocument;
  const response = await makeRequest<null>(config.method, config.url, token, newDocument, config.responseType);
  if (!response.isSuccess) {
    alert("Failed to create document");
    throw new Error("Failed to create document");
  }
}


// rename_document endpoint
export const renameDocument = async (document_id: string, new_name: string, token: string) => {
  const config = { ...requestConfigs.renameDocument, url: `${requestConfigs.renameDocument.url}/${document_id}/${new_name}` };
  const response = await makeRequest<null>(config.method, config.url, token, null, config.responseType);
  if (!response.isSuccess) {
    alert("Failed to rename document");
    throw new Error("Failed to rename document");
  }
}

// delete_document endpoint
export const deleteDocument = async (document_id: string, token: string) => {
  const config = { ...requestConfigs.deleteDocument, url: `${requestConfigs.deleteDocument.url}/${document_id}` };
  const response = await makeRequest<null>(config.method, config.url, token, null, config.responseType);
  if (!response.isSuccess) {
    alert("Failed to delete document");
    throw new Error("Failed to delete document");
  }
}