import React from 'react';
import { Box, Paper, } from '@mui/material';

const BlurBox = ({ children, onClose }) => {
  
  const handleBackgroundClick = () => {
    onClose()
  };

  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  return (
    <Box
      onClick={handleBackgroundClick}
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: (theme) => theme.zIndex.speedDial + 1,

        backdropFilter: 'blur(3px)',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Paper sx={{ margin: 'auto', p: 1 }} onClick={handleContentClick}>
          {children}
        </Paper>
      </Box>
    </Box>
  );
};

export default BlurBox;