import React from 'react';
import { Typography, Box, Stack, Button, Divider, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useFormContext } from '../contexts/FormContext';
import LineView from './LineView';
import { useLineManager } from '../hooks/useLineManager'; 
import { RAMFormControl } from './subcomponents/CustomInputs';
import { useLinePlotContext } from '../contexts/LinePlotContext';
import { defaultInvestmentGrowth, defaultMaxDrawdown, defaultRollingCorrel, defaultRollingReturns, defaultRollingVol, InvestmentGrowth, MaxDrawdown, RollingCorrel, RollingReturns, RollingVol, FormType } from '../contexts/FormContext';
import { RAMTextField, RAMAutoComplete } from './subcomponents/CustomInputs';

const lineTypeOptions: string[] = ['Investment Growth',  'Max Drawdown', 'Rolling Volatility', 'Rolling Returns', 'Rolling Correlation']

const defaultMap: {[key: string]: InvestmentGrowth| MaxDrawdown | RollingCorrel | RollingReturns | RollingVol } = {
    'Investment Growth': defaultInvestmentGrowth,
    'Max Drawdown': defaultMaxDrawdown,
    'Rolling Volatility': defaultRollingVol,
    'Rolling Returns': defaultRollingReturns,
    'Rolling Correlation': defaultRollingCorrel,
}

const LineManager: React.FC = () => {
    const { form, setForm } = useFormContext();
    const { handleAddLine, handleCreateNewLine, handleUpdateLine } = useLineManager();
    const { defaultLineType, setDefaultLineType, dataOptions } = useLinePlotContext();
    
    const handleNewLine = () => {
        const newLine = handleCreateNewLine()
        handleAddLine(newLine)
    }

    const handleSetLineTypes = (newLineType: string) => {
        const defaults = defaultMap[newLineType]
        setForm(prevForm => {
            return { 
                ...defaults, 
                plot_objects: prevForm.plot_objects
            };
        });
        setDefaultLineType(newLineType)
        
    }

    const setWindow = (value: string) => {
        setForm(prevForm => {
            return { 
                ...prevForm, 
                window: Number(value)
            };
        });
    }
    const setStartVal = (value: string) => {
        setForm(prevForm => {
            return { 
                ...prevForm, 
                start_val: Number(value)
            };
        });
    }
    const setBenchmark = (value: string) => {
        setForm(prevForm => {
            return { 
                ...prevForm, 
                benchmark: value
            };
        });
    }
    return (
        <Box height="100%" width="100%">
            <Box >
                <Box display="flex" justifyContent="space-between" alignItems="center"  sx={{px: 0.5, py: 1}}>
                    <Box>
                        <Typography variant="button"  sx={{ fontWeight:800, fontSize:13, width:"fit-content", color:"#c6c6c6"}}>LINE MANAGER</Typography>
                    </Box>

                    <RAMFormControl >
                        <InputLabel>Transform</InputLabel>
                        <Select
                            value={form.plot_type}
                            onChange={(event) => handleSetLineTypes(event.target.value)}
                            label="Transform"
                        >
                            {lineTypeOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                        </Select>
                    </RAMFormControl>
                </Box>
                <Divider/>
                {('benchmark' in form || 'window' in form || 'start_val' in form) && (
                    <>
                        <Box display="flex" width="100%" gap="20px" sx={{p: 1}} flexDirection="row-reverse" alignItems="center">
                            {
                                'benchmark' in form &&
                                    <RAMAutoComplete
                                        disablePortal
                                        options={dataOptions}
                                        value={form.benchmark}
                                        onChange={(event, newValue) => setBenchmark(newValue as string)}
                                        fullWidth
                                        renderInput={(params) => <RAMTextField required {...params} label="Benchmark" />}
                                    />
                            }
                            {
                                'window' in form &&
                                    <RAMTextField 
                                        fullWidth
                                        label={"Window"}
                                        type="number"
                                        value={form.window || ''}
                                        onChange={(event) => setWindow(event.target.value)}
                                        size="small"
                                    />
                            }
                            {
                                'start_val' in form &&
                                    <RAMTextField 
                                        fullWidth
                                        label={"Starting Value"}
                                        type="number"
                                        value={form.start_val || ''}
                                        onChange={(event) => setStartVal(event.target.value)}
                                        size="small"
                                    />
                            }
                        </Box>
                        <Divider/>
                    </>
                )}
                <Stack gap="10px" paddingTop="10px">
                    {Object.entries(form.plot_objects).map(([lineKey, line], index) => (
                        <>
                            <Divider />
                                <Box padding={1}>
                                    <LineView line={line as any}/>
                                </Box>
                            <Divider/>
                        </>
                
                    ))}
                </Stack>
            </Box>
            <Box display="flex" justifyContent="center" marginTop="15px" sx={{px: 2}}>
                <Button 
                    fullWidth 
                    color="inherit" 
                    endIcon={<AddIcon fontSize="small" />}
                    onClick={handleNewLine}
                >
                    <Typography sx={{ fontWeight:800, fontSize:13, color:"#c6c6c6"}}>Add Line</Typography>
                </Button>
            </Box>
        </Box>
    );
};

export default LineManager;