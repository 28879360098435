import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import RDoc from '../../types/RDoc';


interface ExportActionMenuProps {
    rDoc: RDoc;
}

const ExportActionMenu: React.FC<ExportActionMenuProps> = ({ rDoc }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePrintStage = () => {
        const iframeDocument = document.querySelector('iframe.document') as HTMLIFrameElement;
        if (iframeDocument) {
            iframeDocument.contentWindow?.print();
        }
    }

    return (
        <div>
            <Button
                aria-controls="asset-menu"
                aria-haspopup="true"
                onClick={handleClick}
                style={{fontSize: "9pt", color: "white"}}
            >
                Export
            </Button>
            <Menu
                id="asset-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handlePrintStage()}>Print</MenuItem>
            </Menu>
        </div>
  );
};
export default ExportActionMenu;