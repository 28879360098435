import React, { createContext, useContext, ReactNode, useState, useEffect } from 'react';
import { useFormContext } from './FormContext';
import { useAuth0 } from '@auth0/auth0-react';
import { ThemeType, plottingThemes } from '../types/plotting_themes';
import { LineType } from './FormContext';
import { SettingsFormType, defaultInvestmentGrowthSettings, defaultMaxDrawdownSettings, defaultRollingVolatilitySettings, defaultRollingReturnsSettings, defaultRollingCorrelationSettings } from '../types/plotting_backend';
import { getDataOptions } from '../utils/requestUtils';

interface LinePlotConfigContextType {
  dataOptions: string[];
  setDataOptions: (dataOptions: string[]) => void; 
  defaultConfig: { [key: string]: any };
  defaultLineType: string;
  linePlotTheme: ThemeType;
  setLinePlotTheme: (theme: ThemeType) => void;
  setDefaultLineType: (lineType: string) => void; 
  showPerformanceTable: boolean;
  setShowPerformanceTable: (showPerformanceTable: boolean) => void; 
  formValid: boolean;
}

const defaultLinePlotContext: LinePlotConfigContextType = {
  dataOptions: [],
  setDataOptions: () => [],
  defaultConfig: {},
  defaultLineType: 'Investment Growth',
  linePlotTheme: plottingThemes['ReSolve'],
  setLinePlotTheme: () => {}, 
  setDefaultLineType: () => {},
  showPerformanceTable: true,
  setShowPerformanceTable: () => {},
  formValid: false,
};

const lineTypeSettingsMap: { [key: string]: SettingsFormType } = {
  "Investment Growth": defaultInvestmentGrowthSettings,
  "Max Drawdown": defaultMaxDrawdownSettings,
  "Rolling Volatility": defaultRollingVolatilitySettings,
  "Rolling Returns": defaultRollingReturnsSettings,
  "Rolling Correlation": defaultRollingCorrelationSettings,
};

const LinePlotConfigContext = createContext<LinePlotConfigContextType>(defaultLinePlotContext);

export const useLinePlotContext = () => useContext(LinePlotConfigContext);

interface LinePlotConfigProviderProps {
    children: ReactNode;
}
export const LinePlotConfigProvider: React.FC<LinePlotConfigProviderProps> = ({ children }) => {
  const [linePlotTheme, setLinePlotTheme] = useState<ThemeType>(defaultLinePlotContext.linePlotTheme);
  const [defaultLineType, setDefaultLineType] = useState<string>(defaultLinePlotContext.defaultLineType);
  const [dataOptions, setDataOptions] = useState<string[]>(defaultLinePlotContext.dataOptions);
  const [showPerformanceTable, setShowPerformanceTable] = useState<boolean>(defaultLinePlotContext.showPerformanceTable);
  const { form, setForm } = useFormContext()
  const [formValid, setFormValid] = useState<boolean>(defaultLinePlotContext.formValid);
  const { getAccessTokenSilently } = useAuth0();

  // Reassign line colors if theme changes
  useEffect(() => {
    setForm((prevForm) => {
      const updatedLines = prevForm.plot_objects.map((line, index) => {

        const color = linePlotTheme.colors[index % linePlotTheme.colors.length];

        return new LineType(
          line.line_type,
          color,
          line.assets,
          line.name
        );
      });
      return { ...prevForm, plot_objects: updatedLines };
    });
  }, [linePlotTheme, setForm]);

  // Fetch Data Options on mount
  useEffect(() => {
    const fetchDataOptions = async () => {
      const token = await getAccessTokenSilently();
      const dataOptions = await getDataOptions(token);
      setDataOptions(dataOptions);
    }
        fetchDataOptions();
    }, []); 

  // Set form valid if the form contains lines and lines have assets and weights
  useEffect(() => {
    const isValid = form.plot_objects.length > 0 && form.plot_objects.every(line => 
      line.assets.every(asset => 
        asset.asset.length > 0 && Boolean(asset.weight)
      )
    );
  
    setFormValid(isValid);
  }, [form.plot_objects]);


  const value = { 
    ...defaultLinePlotContext, 
    linePlotTheme, 
    setLinePlotTheme, 
    defaultLineType, 
    setDefaultLineType, 
    dataOptions, 
    setDataOptions,
    showPerformanceTable,
    setShowPerformanceTable,
    formValid
  };

  return (
    <LinePlotConfigContext.Provider value={value}>
      {children}
    </LinePlotConfigContext.Provider>
  );
};