import React from 'react';
import { Checkbox, Typography, Grid } from '@mui/material/';
import FormControlLabel from '@mui/material/FormControlLabel';
import { BaseSettingsPanelProps } from './base_settings_pannel';
import { SettingsFormType } from '../../../types/plotting_backend';

interface PerformanceMetricsProps extends BaseSettingsPanelProps {
    performance_metrics: SettingsFormType['performance_table_settings']
}

const PerformanceMetrics: React.FC<PerformanceMetricsProps> = ({performance_metrics, onChange}) => {

    const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        const metric = event.target.name;
        const isChecked = event.target.checked;
        const performanceMetrics = isChecked
            ? [...performance_metrics.performance_metrics, metric]
            : performance_metrics.performance_metrics.filter((m: string) => m !== metric);
        
        onChange('performance_table_settings', 'performance_metrics', performanceMetrics);
    };

    const allMetrics = [
        'Inception Date',
        'Annualized Return',
        'Annualized Volatility',
        'Annualized Sharpe',
        'Maximum Drawdown',
        'Annualized Sortino',
        'Average Drawdown',
        'Best Day',
        'Worst Day',
        'Best Month',
        'Worst Month',
        'Best Year',
        'Worst Year'
    ];

    const checkboxes = allMetrics.map((key) => (
        <Grid item xs={6} key={key}>
            <FormControlLabel
                control={
                    <Checkbox
                        size="small"
                        checked={performance_metrics.performance_metrics.includes(key)}
                        onChange={handleCheck}
                        name={key}
                    />
                }
                label={<Typography style={{ fontSize: '0.8rem' }}>{key}</Typography>}
            />
        </Grid>
    ));

    return (
        <div>
            <Grid container spacing={1} style={{ marginBottom: '20px' }}>
                {checkboxes}
            </Grid>
        </div>
    );
};

PerformanceMetrics.displayName = 'Performance Table Settings';
export default PerformanceMetrics;