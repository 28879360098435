import { saveFile, getFile } from "../utils/requestUtils";

class Asset {
  public id: string;
  public type: string;
  public filename: string;
  public file: File | null;
  public last_saved: string;

  constructor(id: string, type: string, filename: string, last_saved: string) {
    this.id = id;
    this.type = type;
    this.filename = filename;
    this.file = null;
    this.last_saved = last_saved;
  }
  async get(token: string) {
    try {
      this.file = await getFile(this.id, this.filename, token);
      return this.file;
    } catch (error) {
      throw new Error("Unable to get file");
    }
  }
  async save(token: string): Promise<void> {
    if (this.file) {
      await saveFile(this.file, this.id, this.filename, token)
    } else {
      throw new Error("No file to save.\nRun asset.get prior to running asset.save")
    }
  }
}
export default Asset;
