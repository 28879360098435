

import React, { useContext, ChangeEvent } from 'react';
import { FormContext } from '../../../contexts/FormContext';
import { TextField, Switch, Box } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { FormType } from '../../../contexts/FormContext';
import { SettingsFormType } from '../../../types/plotting_backend';
import { styled } from '@mui/material/styles';

export interface BaseSettingsProps {

}

type GroupKeys = keyof SettingsFormType;
export type SettingKeys = keyof SettingsFormType['chart_settings'] | keyof SettingsFormType['legend_settings'] | keyof SettingsFormType['date_settings'] | keyof SettingsFormType['axis_settings'] | keyof SettingsFormType['horizontal_line_settings'] | keyof SettingsFormType['title_settings']; 

export const RAMTextField = styled(TextField)({
    '& .MuiInputBase-root': {
      padding: '0',
    },
    '& .MuiInputBase-input': {
      padding: '8px 8px',
      fontSize: '13px',
    },
    '& .MuiInputLabel-root': {
      transform: 'translate(14px, 8px) scale(1)',
      fontSize: '13px',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
      fontSize: '13px',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1rem',
    },
    '& .MuiChip-root': {
      fontSize: '10px',
      padding: 0,
    },
  });

export const RAMFormControl = styled(FormControl)({
    '& .MuiInputBase-root': {
      padding: '0',
      lineHeight: 'unset'
    },
    '& .MuiInputBase-input': {
      padding: '8px 8px',
      fontSize: '13px',
    },
    '& .MuiInputLabel-root': {
      transform: 'translate(14px, 8px) scale(1)',
      fontSize: '13px',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
      fontSize: '13px',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1rem',
    },
    '& .MuiChip-root': {
      fontSize: '10px',
      padding: 0,
      height: 'unset',
    '& .Mui-Select': {
      height: 35,
    }
    },
  });

