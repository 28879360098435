import React from 'react';
import { styled, ButtonBase } from '@mui/material';
import { FileItem } from './FileIcon';
import Typography from '@mui/material/Typography';

const FileButton = styled(ButtonBase)(({ theme, selected }) => ({
  isplay: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  color: theme.palette.text.secondary,
  width: '100%',
  margin: '0',
  padding: '0',
  backgroundColor: selected ? 'lightblue' : 'transparent',
  transition: theme.transitions.create(['background-color', 'border-color', 'box-shadow']),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));


export default function CustomFileButton({ selected, onClick, asset }) {

  return (
    <div>
      <FileButton selected={selected} onClick={onClick}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderLeft: '1px solid gray',
          paddingLeft: '15px',
          paddingRight: '10px',
          marginLeft: '25px',
          height: '30px',
          width: '100%',
        }}>
          <div style={{ display: 'flex', alignItems: 'center', width: "100%"}}>
            <FileItem type={asset.type} />
            <Typography sx={{ fontSize: '0.75rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '225px' }}>
              {asset.filename}
            </Typography>
          </div>
        </div>
      </FileButton>
    </div>
  );
}