import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/ButtonBase';
import BetaIcon from './icons/BetaIcon'
import { useHistory } from 'react-router-dom';
import { alpha } from '@mui/material/styles';

const AppIcon = ({ AppName, AppDescription, path, beta, disabled }) => {
    const history = useHistory();
    return (
      <Button 
        sx = {{
          textAlign:"left",
          height:"180px",
          width:"500px",
          overflow:"hidden",
          backgroundColor: disabled ? alpha("#000", 0.12) : "", 
          pointerEvents: disabled ? "none" : "auto",
          opacity: disabled ? 0.5 : 1, 
        }}
        onClick = {() => { 
          if (!disabled) {
            history.push(`/${path}`);
          }
        }}
        disabled={disabled}
      >
        <Paper elevation = {3} sx={{ padding:"30px", opacity: disabled ? 0.5 : 1 }}>
          
          <Stack>
            
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <h3 style={{ opacity: disabled ? 0.5 : 1 }}>{AppName}</h3>
              </Grid>
              <Grid item>
                {beta && <BetaIcon />}
              </Grid>
            </Grid>
            
            <Box>
              <p style={{ opacity: disabled ? 0.5 : 1 }}>{AppDescription}</p>
            </Box>
          </Stack>
  
        </Paper>
      </Button>
    );
};

export default AppIcon;