import React from 'react';
import { RAMTextField, RAMFormControl } from './base_settings';
import { BaseSettingsPanelProps } from './base_settings_pannel';
import { Select, InputLabel, MenuItem, Box } from '@mui/material';
import { SettingsFormType } from '../../../types/plotting_backend';

interface HorizontalLineSettingsProps extends BaseSettingsPanelProps {
    horizontal_line_settings: SettingsFormType['horizontal_line_settings']
}

const HorizontalLineSettings: React.FC<HorizontalLineSettingsProps> = ({horizontal_line_settings, onChange}) => {

    const typeOptions = [
        { label: 'Solid', value: 'Solid' },
        { label: 'Dashed', value: 'Dashed' },
        { label: 'Dotted', value: 'Dotted' },
        { label: 'Dot-Dash', value: 'Dot-Dash' },
        { label: 'Long-Dash', value: 'Long-Dash' },
        { label: 'Two-Dash', value: 'Two-Dash' },
    ];

    return (
        <Box display="flex" flexDirection="column" gap="15px">
            <RAMTextField 
                label={"Placement"}
                value={horizontal_line_settings.place_horizontal_line_at}
                onChange={(event) => onChange("horizontal_line_settings", "place_horizontal_line_at", event.target.value)}
                size="small"
            />
            <RAMTextField 
                label={"Color"}
                value={horizontal_line_settings.horizontal_line_color}
                onChange={(event) => onChange("horizontal_line_settings", "horizontal_line_color", event.target.value)}
                size="small"
            />
            <RAMTextField 
                label={"Size"}
                value={horizontal_line_settings.horizontal_line_size}
                onChange={(event) => onChange("horizontal_line_settings", "horizontal_line_size", event.target.value)}
                size="small"
            />
            <RAMFormControl size="small">
                <InputLabel>{"Type"}</InputLabel>
                <Select
                    value={horizontal_line_settings.horizontal_line_type}
                    onChange={(event) => onChange("horizontal_line_settings", "horizontal_line_type", event.target.value)}
                    label={"Type"}
                >
                    {typeOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </RAMFormControl>
        </Box>
    
    );
};

HorizontalLineSettings.displayName = 'Horizontal Line Settings';
export default HorizontalLineSettings;