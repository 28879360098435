import React from 'react';
import { BaseSettingsPanelProps } from './base_settings_pannel';
import { RAMFormControl } from './base_settings';
import { Select, MenuItem, InputLabel, Box } from '@mui/material';
import { SettingsFormType } from '../../../types/plotting_backend';

interface LegendSettingsProps extends BaseSettingsPanelProps {
    legend_settings: SettingsFormType['legend_settings']
}

const LegendSettings: React.FC<LegendSettingsProps> = ({legend_settings, onChange}) => {

    const titlePositionOptions = [
        { label: 'Left', value: 'left' },
        { label: 'Right', value: 'right' },
        { label: 'Top', value: 'top' },
        { label: 'Bottom', value: 'bottom' },
        { label: 'None', value: 'none' },
    ];

    const directionOptions = [
        { label: 'Horizontal', value: 'Horizontal' },
        { label: 'Vertical', value: 'Vertical' },
    ];

    return (
            <Box display="flex" flexDirection="column" gap="15px">
                <RAMFormControl size="small">
                        <InputLabel>{"Title Position"}</InputLabel>
                        <Select
                            value={legend_settings.legend_position}
                            onChange={(event) => onChange("legend_settings", "legend_position", event.target.value)}
                            label={"Title Position"}
                        >
                            {titlePositionOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </RAMFormControl>
                    <RAMFormControl size="small">
                        <InputLabel>{"Legend Size"}</InputLabel>
                        <Select
                            value={legend_settings.legend_direction}
                            onChange={(event) => onChange("legend_settings", "legend_direction", event.target.value)}
                            label={"Legend Size"}
                        >
                            {directionOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </RAMFormControl>
            </Box>
    );
};
LegendSettings.displayName = 'Legend Settings';
export default LegendSettings;