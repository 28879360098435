import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import * as XLSX from 'xlsx';

const UniversalFileViewer = ({ file }) => {
  const [stage, setStage] = useState(null);

  useEffect(() => {
    const loadForDisplay = async () => {
      switch (file.type) {
        case 'text/html':
          return loadHtml();
        case 'image/jpg':
        case 'image/jpeg':
        case 'image/png':
        case 'image/gif':
          return loadImage();
        case 'text/csv':
          return loadCsv();
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          return loadExcel();
        default:
          throw new Error(`Unsupported file type: ${file.type}`);
      }
    };

    const loadHtml = async () => {
      const htmlContent = await file.text();
      return <iframe className="document" style={{width:'100%', height:'100%'}} srcDoc={htmlContent} title="HTML Content" />;
    };

    const loadImage = async () => {
      const imageUrl = URL.createObjectURL(file);
      return <img style={{width:"75%", margin:"auto"}} src={imageUrl} alt="Loaded Image" />;
    };

    const loadCsv = async () => {
      const csvContent = await file.text();
      const rows = csvContent.split('\n').map(row => row.split(','));
      return (
        <TableContainer component={Paper} style={{maxHeight: '100%', maxWidth: '75%', width: 'auto', height: 'auto', overflow: 'auto', margin: '15px auto'}}>
          <Table stickyHeader>
            <TableBody>
              {rows.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <TableCell key={cellIndex}>{cell}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    };

    const loadExcel = async () => {
      const arrayBuffer = await file.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer, { type: 'buffer' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      return (
        <TableContainer component={Paper} style={{ maxHeight: '100%', maxWidth: '75%', width: 'auto', height: 'auto', overflow: 'auto', margin: '15px auto' }}>
          <Table stickyHeader>
            <TableBody>
              {data.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <TableCell key={cellIndex}>{cell}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    };

    if (file) {
      loadForDisplay().then(setStage).catch(console.error);
    }
  }, [file]);

  if (!file) {
    console.log("no file")
    return <div></div>;
  }

  return (
    <div style={{width:"100%", height:"100vh", display:'flex', alignContent: 'center'}}>
      {stage}
    </div>
  );
};

export default UniversalFileViewer;