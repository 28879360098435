import React, { useState } from 'react';
import { InputLabel, Select, MenuItem, Switch, FormControlLabel, Typography, Box } from '@mui/material';
import { RAMFormControl, RAMTextField } from './base_settings';
import { BaseSettingsPanelProps } from './base_settings_pannel';
import { SettingsFormType } from '../../../types/plotting_backend';

interface ChartSettingsProps extends BaseSettingsPanelProps {
    chart_settings: SettingsFormType['chart_settings']
}

const ChartSettings: React.FC<ChartSettingsProps> = ({chart_settings, onChange}) => {
    const [selectedDimension, setSelectedDimension] = useState('550,1400');

    const dimensionPresets = {
        'Default': [550, 1400],
        'PowerPoint': [600, 1280],
        'Word/PDF': [550, 800]
    };

    const handleDimensionSelect = (dimensions: string) => {
        setSelectedDimension(dimensions);
        const dimensionsArray = dimensions.split(",").map(Number);
        onChange('chart_settings', 'figure_height', dimensionsArray[0])
        onChange('chart_settings', 'figure_width', dimensionsArray[1])
    }

    return (
        <Box display="flex" flexDirection="column" gap="15px" >
            <RAMFormControl size="small">
                <InputLabel>Dimensions</InputLabel>
                <Select
                    value={selectedDimension} 
                    onChange={(event) => handleDimensionSelect(event.target.value as string)}
                    label="Dimensions"
                >
                    {Object.entries(dimensionPresets).map(([key, value]) => (
                        <MenuItem key={key} value={value.join(",")}>
                            {key}
                        </MenuItem>
                    ))}
                </Select>
            </RAMFormControl>
                <RAMTextField 
                    label={"Figure Height"}
                    value={chart_settings.figure_height}
                    onChange={(event) => onChange("chart_settings", "figure_height", event.target.value)}
                    size="small"
                />
                <RAMTextField 
                    label={"Figure Width"}
                    value={chart_settings.figure_width}
                    onChange={(event) => onChange("chart_settings", "figure_width", event.target.value)}
                    size="small"
                />
                <Box display="flex">
                    <FormControlLabel
                        sx={{px:1, width: "fit-content"}}
                        control={
                            <Switch
                                checked={chart_settings.panel_border}
                                onChange={(event) => onChange("chart_settings", "panel_border", event.target.checked)}
                                size="small"
                            />
                        }
                        label={<Typography sx={{fontSize: 12}} >Panel Border</Typography>}
                    
                    />
                    <FormControlLabel
                        sx={{px:1, width: "fit-content"}}
                        control={
                            <Switch
                                checked={chart_settings.show_grid}
                                onChange={(event) => onChange("chart_settings", "show_grid", event.target.checked)}
                                size="small"
                            />
                        }
                        label={<Typography sx={{fontSize: 12}} >Show Grid</Typography>}
                    />
                </Box>

            </Box>
    );
};

ChartSettings.displayName = 'Chart Settings';
export default ChartSettings;