import { useCallback } from 'react';
import { useFormContext } from '../contexts/FormContext';
import { useLinePlotContext } from '../contexts/LinePlotContext';
import { LineType, FormType } from '../contexts/FormContext';


export const useLineManager = () => {
    const { form, setForm } = useFormContext();
    const { linePlotTheme, defaultLineType } = useLinePlotContext();

    const getNewLineColor = useCallback((numberOfLines: number) => {
        return linePlotTheme.colors[numberOfLines % linePlotTheme.colors.length];
    }, [linePlotTheme.colors]);

    const handleCreateNewLine = useCallback((line_type?: string, color?: string, assets?: any, name?: string) => {
        const newLineType = line_type || defaultLineType;
        const newLineColor = color || linePlotTheme.colors[form.plot_objects.length % linePlotTheme.colors.length];
        const newLine = new LineType(newLineType, newLineColor, assets, name);
        return newLine;
    }, [form.plot_objects.length, linePlotTheme.colors, defaultLineType]);

    const handleAddLine = useCallback((newLine: LineType) => {
        setForm((prevForm) => ({
            ...prevForm,
            plot_objects: [...prevForm.plot_objects, newLine],
        }));
    }, [setForm]);

    const handleUpdateLine = useCallback((line: LineType) => {
        setForm((prevForm) => {
            const updatedLines = prevForm.plot_objects.map(l => 
                l.object_id === line.object_id ? new LineType(line.line_type, line.color, line.assets, line.name, line.benchmark ) : l
            );
            return {
                ...prevForm,
                plot_objects: updatedLines,
            };
        });
    }, [setForm]);


    const handleDeleteLine = useCallback((lineKey: string) => {
        setForm((prevForm) => ({
            ...prevForm,
            plot_objects: prevForm.plot_objects.filter(plot_objects => plot_objects.object_id !== lineKey),
        }));
    }, [setForm]);

    return {
        form,
        handleCreateNewLine,
        handleUpdateLine,
        handleDeleteLine,
        handleAddLine,
        getNewLineColor,
    };
};