import React from 'react';
import TableChartIcon from '@mui/icons-material/TableChart';
import ImageIcon from '@mui/icons-material/Image';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

export function FileItem({ type }) {
  let Icon;
  switch (type) {
    case 'csv':
      Icon = TableChartIcon;
      break;
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'gif':
      Icon = ImageIcon;
      break;
    default:
      Icon = InsertDriveFileIcon;
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Icon sx={{ fontSize: '.9rem', marginRight: '8px' }} />
    </div>
  );
}
