import Box from '@mui/material/Box';

const RamLogo = ({ width = "150px", sx = {} }) => {
  return (
    <Box sx={{ ...sx, display: 'flex', alignItems: 'center' }}>
        <a href="/">
            <img
                src="ram-logo.png"
                alt="Logo"
                style={{
                    width: width,
                    verticalAlign: 'bottom',
                }}
            />
        </a>
    </Box>
  );
};

export default RamLogo;