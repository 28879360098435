import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Button from '@mui/material/Button';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import CustomFileButton from './FileButton';
import DeleteIcon from '@mui/icons-material/Delete'; 
import { Box } from '@mui/material';

const commonBackgroundColor = 'rgba(0, 0, 0, .03)'; 

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: 'none',
  '&:not(:last-child)': {
    borderBottom: 'none',
  },
  '&::before': {
    display: 'none',
  },
  color: '#dcdcdc'
}));

const AccordionSummary = styled(({ onIconClick, ...otherProps }) => (
  <MuiAccordionSummary
    expandIcon={
      <Button onClick={(event) => {
        event.stopPropagation();
        if (onIconClick) {
          onIconClick(event);
        }
      }}>
        <ArrowForwardIosSharpIcon sx={{ fontSize: '0.7rem' }} />
      </Button>
    }
    {...otherProps}
  />
))(({ theme }) => ({
  backgroundColor: commonBackgroundColor,
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    marginTop: '0',
    marginBottom: '0',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    pointerEvents: 'auto',
  },
  '& .MuiButtonBase-root': {
    minWidth: 'auto',
    padding: theme.spacing(0.5), 
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));


const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '0px',
  backgroundColor: commonBackgroundColor, 
}));








export default function FileAccordion({ documentObjects, onDocumentSelect, onFileSelect, onDocumentDelete }) {
  const [expanded, setExpanded] = useState(false);

  const handleClick = (doc) => {
      onDocumentSelect(doc)
  };
  const handleIconClick = (event, doc) => {
    setExpanded(expanded === doc.id ? false : doc.id);
  };


  return (
    <div>
      {documentObjects.map((doc, index) => (
        <Accordion
          key={doc.filename}
          expanded={expanded === doc.id}
        >
          <AccordionSummary
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
            onIconClick={(event) => handleIconClick(event, doc)}

          >
            <Box sx={{alignItems: "center", justifyContent: "space-between", width: "100%", display:"flex"}}>
              <Button sx={{ textTransform: 'none', color:'white' }} onClick={() => handleClick(doc)}>
                <Typography sx={{ fontSize: '0.75rem', lineHeight: 'normal', margin: 0, padding: 0 }}>
                  {doc.name}
                </Typography>
              </Button>


                

                <DeleteIcon onClick={async () => await onDocumentDelete(doc)} sx={{fontSize: 15, color:'white', '&:hover': { backgroundColor: 'darkred' } }} />

            </Box>
          </AccordionSummary>
          <AccordionDetails>
          {doc.assets.map((asset) => (
            <CustomFileButton 
              key={asset.filename}
              asset={asset} 
              onClick={() => onFileSelect(asset)}
            />
          ))}
        </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
