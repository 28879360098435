import React, { useEffect } from 'react';
import { useLineManager } from '../hooks/useLineManager';
import { Box, Stack, TextField, Button, Typography, IconButton, MenuItem, Select, InputLabel, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useLinePlotContext } from '../contexts/LinePlotContext';
import { RAMFormControl, RAMAutoComplete, RAMTextField } from './subcomponents/CustomInputs';
import AddTaskIcon from '@mui/icons-material/AddTask';
import DeleteIcon from '@mui/icons-material/Delete';

const LineView = ({line}) => {
    const { handleUpdateLine, handleDeleteLine } = useLineManager();
    const { linePlotTheme, dataOptions } = useLinePlotContext();
    const [newAsset, setNewAsset] = React.useState('');
    const [newWeight, setNewWeight] = React.useState();

    const handleDelete = () => {
        handleDeleteLine(line.object_id);
    }

    const handleAddAssetWeight = () => {
        if (newAsset && newWeight) {
            const newAssets = [...line.assets, { asset: newAsset, weight: newWeight }];
            handleUpdateLine({ ...line, assets: newAssets });
            setNewAsset('');
            setNewWeight('');
        }
    };
    

    const handleUpdateAssetWeight = (index, asset, weight) => {
        const updatedAssets = line.assets.map((item, idx) => {
            if (idx === index) {
                return { asset: asset, weight: weight };
            }
            return item;
        });
        handleUpdateLine({ ...line, assets: updatedAssets });
    };

    const handleDisplayNameChange = (event) => {
        handleUpdateLine({ ...line, name: event.target.value });
    };

    const handleColorChange = (event) => {
        handleUpdateLine({ ...line, color: event.target.value });
    };

    return (
        <>
            <Stack sx={{p: .5}} gap={1}>
                <Box display="flex" justifyContent="space-between" alignItems="center" paddingBottom="10px">
                    <Box display="flex" gap="10px">
                        {/* Display Name Field */}
                        <RAMTextField
                            sx={{ minWidth: "150px" }}
                            label="Display Name"
                            value={line.name || line.nameFromAssets()}
                            onChange={handleDisplayNameChange}
                            size="small"
                        />

                        {/* Line Color Field */}
                        <RAMFormControl>
                            <InputLabel>Color</InputLabel>
                            <Select
                                value={line.color}
                                onChange={handleColorChange}
                                label="Color"
                            >
                                {linePlotTheme.colors.map((color) => (
                                    <MenuItem key={color} value={color}>
                                        <Box width="30px" height="18px" sx={{backgroundColor: color}}></Box>
                                    </MenuItem>
                                ))}
                            </Select>
                        </RAMFormControl>
                    </Box>

                    {/* Delete Line */}
                    <IconButton
                        sx={{p:0}}
                        onClick={handleDelete}
                    >
                        <DeleteIcon sx={{fontSize: '15pt'}} />
                    </IconButton>

                </Box>

                <Divider />

                {/* Asset Section and Weight */}
                {line.assets.map((pair, index) => {
                    return (
                        <Box key={index} display="flex" alignItems="center" gap="10px">
                            

                            {/* Select Asset Field */}
                            <RAMAutoComplete
                                disablePortal
                                id={`combo-box-demo-${index}`}
                                options={dataOptions}
                                value={pair.asset}
                                onChange={(event, newValue) => handleUpdateAssetWeight(index, newValue || '', pair.weight)}
                                fullWidth
                                renderInput={(params) => <TextField required {...params} label="Asset" />}
                            />

                            {/* Asset Weight Field */}
                            <RAMTextField
                                required
                                type="number"
                                sx={{ maxWidth: "80px" }}
                                label="Weight"
                                value={pair.weight}
                                onChange={(e) => handleUpdateAssetWeight(index, pair.asset, e.target.value)}
                                size="small"
                            />

                            {/* Delete Asset Button */}
                            <IconButton
                                disabled={line.assets.length <= 1}
                                sx={{ p: "0"}}
                                onClick={() => {
                                    const updatedAssets = line.assets.filter((_, idx) => idx !== index);
                                    handleUpdateLine({ ...line, assets: updatedAssets });
                                }}
                                aria-label="remove asset-weight pair"
                            >
                                <CloseIcon sx={{fontSize: "20px"}}/>
                            </IconButton>

                        </Box>
                    );
                })}

                {/* New Asset Input */}
                <Box display="flex" alignItems="center" gap="10px">
                    {/* Select Asset Field */}
                    <RAMAutoComplete
                        disablePortal
                        id="new-asset-autocomplete"
                        options={dataOptions}
                        renderInput={(params) => <TextField {...params} label="Asset" />}
                        fullWidth
                        value={newAsset}
                        onChange={(event, newValue) => setNewAsset(newValue)}
                    />

                    {/* Asset Weight Field */}
                    <RAMTextField
                        sx={{ maxWidth: "80px" }}
                        type="number"
                        label="Weight"
                        size="small"
                        value={newWeight}
                        onChange={(e) => setNewWeight(e.target.value)}
                    />

                    {/* Add Asset Button */}
                    <IconButton
                        color="success"
                        sx={{ p: "0"}}
                        aria-label="add asset-weight pair"
                        disabled={!newAsset || !newWeight}
                        onClick={handleAddAssetWeight}
                    >
                        <AddTaskIcon sx={{fontSize: "20px"}}/>
                    </IconButton>
                </Box>
                    
            </Stack>
        </>
    );
}

export default LineView;