import React from "react";
import ResponsiveAppBar  from "../common/components/ResponsiveAppBar";
import AppIcon  from "../common/components/AppIcon";
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';



const AppsHomeScreen = () => {
  const theme = useTheme();

  return (
    <div>
      <ResponsiveAppBar styles={{ backgroundColor: theme.palette.background.default }}/>
        
        <Container width={500}>
          
          <Grid container sx={{ marginTop: "100px", rowGap: "20px" }} justifyContent="space-evenly"> 
            <Grid item>
              <AppIcon path="podcast_post" AppName="Podcast Post" AppDescription="Automagically identify the key topics covered in a podcast by uploading a transcript. Receive a Word document containing Landing Page show-notes, a full episode summary, and a list of topic summaries."/>
            </Grid>
            <Grid item>
              <AppIcon path="document_creator" AppName="Document Creator" AppDescription="Create documents with automated data retreival and generative text content." beta="true"/>
            </Grid>
            <Grid item>
              <AppIcon path="plotting" AppName="Plotting" AppDescription="Interactive interface to create line plots, bar plots, correlation matrices, and waterfall plots." beta="true"/>
            </Grid>
          </Grid>
        
        </Container>
      
    </div>
  );
};

export default AppsHomeScreen;