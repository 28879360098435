import React from 'react';
import { Typography, Box, Divider, Switch, Stack, Checkbox, Tooltip, IconButton } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useFormContext, TableObject } from '../contexts/FormContext';
import { useLinePlotContext } from '../contexts/LinePlotContext';
import PerformanceMetrics from './subcomponents/settings_pannels/performance_metrics';
import { useSettingsManager } from '../hooks/useSettingsManager';
import ExportManager from './ExportManager';
import TableSettings from './subcomponents/settings_pannels/table_settings';

const PerformanceTableManager: React.FC = () => {
    const { performanceTable, setPerformanceTable } = useFormContext();
    const { showPerformanceTable, setShowPerformanceTable, formValid } = useLinePlotContext();
    const { updatePerformanceTableSettings } = useSettingsManager();

    const handleExcessReturnsChange = (object_id: string, value: boolean) => {
        setPerformanceTable((prevPerformanceTable) => {
            return {
                ...prevPerformanceTable,
                plot_objects: prevPerformanceTable.plot_objects.map((tableObject) => {
                    if (tableObject.object_id === object_id) {
                        return new TableObject(
                            tableObject.assets,
                            tableObject.name,
                            value
                        );
                    }
                    return tableObject;
                }),
            };
        });
    }

    return (
        <Box height="100%" width="100%">
            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ p: 0.5 }}>
                <Typography variant="button" sx={{ fontWeight: 800, fontSize: 13, width: "fit-content", color: "#c6c6c6" }}>
                    Performance Table
                </Typography>
                <Box display="flex">
                    <Switch
                        checked={showPerformanceTable}
                        onChange={(event) => setShowPerformanceTable(event.target.checked)}
                        size="small"
                    />
                    <Box height="20px">
                        <ExportManager variant='text' disabled={!formValid} form={performanceTable} />
                    </Box>
                </Box>
            </Box>
            <Divider />
            <Box padding="5px 20px 0 20px">
                <PerformanceMetrics onChange={updatePerformanceTableSettings} performance_metrics={performanceTable.settings.performance_table_settings} />
            </Box>
            < Divider />
            <Box padding="20px">
                <TableSettings onChange={updatePerformanceTableSettings} performance_table_settings={performanceTable.settings.performance_table_settings} />
            </Box>
            < Divider />
            <Stack paddingTop="20px">
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="button" sx={{ fontWeight: 800, fontSize: 13, width: "fit-content", color: "#c6c6c6", pl: .5 }}>
                    Subtract Risk Free Rate
                </Typography>
                <Tooltip title="Select for any series where the risk free rate should be subtracted when computing the Sharpe and Sortino ratios.">
                    <IconButton size="small">
                        <HelpOutlineIcon fontSize="inherit" />
                    </IconButton>
                </Tooltip>
            </Box>
                <Divider/>
                {performanceTable.plot_objects.map((tableObject, index) => (
                    <>
                        <Box sx={{px:2}} display="flex" alignItems="center" justifyContent="space-between" key={index}>
                            <Typography style={{ fontSize: '0.8rem', padding: 1 }}>
                                {tableObject.name || tableObject.nameFromAssets()}
                            </Typography>
                            <Checkbox
                                checked={tableObject.excess_returns}
                                onChange={(event) => handleExcessReturnsChange(tableObject.object_id, event.target.checked)}
                            />
                        </Box>
                        <Divider/>
                    </>
                ))}
            </Stack>
        </Box>
    );
};

export default PerformanceTableManager;