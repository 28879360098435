import React, { useState } from 'react';
import RDoc from '../../types/RDoc';
import ExportActionMenu from './ExportActionMenu';
import FileActionMenu from './FileActionMenu';

interface DocActionBarProps {
    rDoc: RDoc;
    refreshDocuments: () => void;
}


const DocActionBar: React.FC<DocActionBarProps> = ({ rDoc, refreshDocuments }) => {
  const [lastSave, setLastSave] = useState('');

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '8px', backgroundColor: "#141414", border: "1px solid rgb(255 255 255 / 12%)" }}>
            <div style={{ flex: 1, display: 'flex' }}> 
              <FileActionMenu rDoc={rDoc} refreshDocuments={refreshDocuments} setSaveTime={setLastSave}/>
              <ExportActionMenu rDoc={rDoc}/>
            </div>
            <span style={{ color: 'white', alignSelf: 'center', margin: '0 auto' }}>{rDoc.name}</span>
            <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
              <span style={{ color: 'white', alignSelf: 'center', marginRight: '8px', fontSize: '9pt' }}>Last saved: <i>{lastSave}</i></span>
            </div>
        </div>
  );
};
export default DocActionBar;