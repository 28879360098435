import Asset from "./Asset";
import { copyDocument, createDocument, deleteDocument, renameDocument } from "../utils/requestUtils";

function generateRandomId(length: number): string {
  const array = new Uint8Array(length);
  window.crypto.getRandomValues(array);
  return btoa(String.fromCharCode.apply(null, array as unknown as number[]))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
}

class RDoc {
  public assets: Asset[];
  public category: string;
  public last_saved: string;
  public name: string;
  public id: string;
  public available: boolean;
  public originalImageSources: { [key: string]: string };
  public isTemplate: boolean;

  constructor(name: string, category: string, assets: Asset[], id: string, available: boolean) {
    this.assets = assets;
    this.last_saved = '';
    this.category = category;
    this.name = name;
    this.id = id;
    this.available = available;
    this.originalImageSources = {};
    this.setID(id);
    if (category === 'Templates') {
      this.isTemplate = true
    } else {
      this.isTemplate = false
    }
  }

  static assetFromJSON(json: any): Asset {
    return new Asset(json.id, json.type, json.filename, json.last_saved);
  }

  static fromJSON(json: any): RDoc {
    const assets = json.assets.map(RDoc.assetFromJSON);
    return new RDoc(json.name, json.category, assets, json.id, json.available);
  }

  async saveCopy(copy_name: string, token: string) {
    const newID = generateRandomId(16);


    // Create Duplicate of Current file with New Name
    const copied_document = new RDoc(
      copy_name, 
      "user", 
      this.assets.map(asset => new Asset(asset.id, asset.type, asset.filename, asset.last_saved)), 
      newID, 
      this.available
    );
    await copyDocument(this.id, newID, token);
    await createDocument(copied_document, token)
    return copied_document;
  }

  setID(id: string): void {
    this.id = id;
    for (const asset of this.assets) {
      asset.id = id;
    } 
  }
  async rename(new_name: string, token: string) {
    await renameDocument(this.id, new_name, token)
  };

  async delete(token: string) {
    await deleteDocument(this.id, token)
  };
}

export default RDoc;