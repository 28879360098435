import React, { useState, useEffect } from 'react';
import { useTheme, Drawer, Divider, Theme, CircularProgress } from '@mui/material';
import FileAccordion from '../components/FileMenu/FileAccordian';
import RDoc from '../types/RDoc'; 
import Asset from '../types/Asset'; 
import StartMenu from '../components/StartMenu';
import DocumentActionBar from '../components/DocActionBar/DocActionBar';
import UniversalFileViewer from '../components/UniversalFileViewer';
import { useAuth0 } from '@auth0/auth0-react';
import RamLogo from '../../../common/components/RAMLogo';
import { listDocuments } from '../utils/requestUtils';

const DocumentCreatorScreen: React.FC = (): React.ReactElement => {
    const { getAccessTokenSilently } = useAuth0();
    const theme: Theme = useTheme();  
    const [documents, setDocuments] = useState<RDoc[]>([]); 
    const [currentDocument, setCurrentDocument] = useState<RDoc | null>(null); 
    const [stage, setStage] = useState<File>();
    const [showStartMenu, setShowStartMenu] = useState(true);
    const [showLoading, setShowLoading] = useState(false)

    const fetchDocuments = async () => {
        const token = await getAccessTokenSilently();
        const rDocs = await listDocuments(token);
        setDocuments(rDocs);
    };
  
    // Fetches all documents on component mount
    useEffect(() => {
      fetchDocuments();
    }, []);

    // Fetches all documents if current document props change
    useEffect(() => {
        fetchDocuments();
      }, [currentDocument]);

    
    // Function to place asset on stage
    const handleFileSelect = async (asset: Asset) => {
        setShowLoading(true);
        try {
            const token = await getAccessTokenSilently();
            let file = await asset.get(token);
            setStage(file);
        } catch (error) {
            alert('Failed to load file');
            console.error('Error loading file:', error);
        } finally {
            setShowLoading(false);
        }
    };

    // Function to place document on stage
    const handleDocumentSelect = async (document: RDoc) => {
        const token = await getAccessTokenSilently();
        try {
            setShowStartMenu(false);
            let load_doc: RDoc | undefined;

            if (document.isTemplate) {
                const newName = prompt("Please enter a name for the new document:");
                if (newName) {
                    const docCopy = await document.saveCopy(newName, token);
                    load_doc = docCopy
                }
            } else {
                load_doc = document;
            }

            if (load_doc) {
                setCurrentDocument(load_doc);
                let htmlAsset = load_doc.assets.find(asset => asset.type === '.html');
                if (htmlAsset) {
                    await handleFileSelect(htmlAsset);
                }
            }
        } catch (error) {
            console.error('Error handling document selection:', error);
        }
    };

    const handleDocumentDelete = async (document: RDoc) => {
        const token = await getAccessTokenSilently();
        await document.delete(token)
        await fetchDocuments()
        setStage(undefined)
        setCurrentDocument(null)
    }


    return (
        <div style={{ display: 'flex' }}>
            {showStartMenu && (
                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 2 }}>
                    <StartMenu 
                        templates={documents.filter(doc => doc.isTemplate)} 
                        drafts={documents.filter(doc => !doc.isTemplate)} 
                        onDocumentSelected={handleDocumentSelect}   
                    />
                </div>
                )}
            {showLoading && (
                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 2 }}>
                    <CircularProgress />
                </div>
            )}
                
                <Drawer
                    variant="permanent"
                    anchor="left"
                    style={{ width: 300, filter: showStartMenu ? 'blur(4px)' : 'none', }}
                    sx={{ '& .MuiDrawer-paper': { width: 300, boxSizing: 'border-box' } }}
                >
                    <div style={{ display: 'flex', alignItems: 'center', padding: '10px px' }}>
                        <RamLogo/>
                    </div>
    
                    <Divider />
    
                    <FileAccordion onDocumentDelete={handleDocumentDelete} documentObjects={documents.filter(doc => !doc.isTemplate)} onDocumentSelect={handleDocumentSelect} onFileSelect={handleFileSelect} />
                </Drawer>
                
    
                <main style={{ flexGrow: 1, overflow: 'auto', filter: showStartMenu ? 'blur(4px)' : 'none'}}>
                    {currentDocument && <DocumentActionBar rDoc={currentDocument} refreshDocuments={fetchDocuments} />}
                    <div
                        style={{
                            width: '100%',
                            height: 'calc(100vh - 51px)', 
                            margin: '0 auto',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            backgroundColor: theme.palette.background.default,
                        }}
                    >
                        { stage && <UniversalFileViewer file={stage} /> }
                    </div>
                </main>
            </div>
    );
};
export default DocumentCreatorScreen;
