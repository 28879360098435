import React, { ReactNode, createContext, useContext, useState, useEffect } from 'react';
import { defaultInvestmentGrowthSettings, defaultMaxDrawdownSettings, defaultPerformanceTableSettings, defaultRollingCorrelationSettings, defaultRollingReturnsSettings, defaultRollingVolatilitySettings, SettingsFormType } from '../types/plotting_backend';
import { v4 as uuidv4 } from 'uuid';
import { ThemeType, plottingThemes } from '../types/plotting_themes';


export abstract class BaseObject {
    name?: string;
    object_id: string;
    assets: { asset: string, weight: number }[];

    constructor(
        assets?: { asset: string, weight: number }[],
        name?: string
    ) {
        this.object_id = uuidv4();
        this.assets = assets || [{ asset: '', weight: 100 }];
        this.name = name;

    }

    public nameFromAssets(): string {
        if (this.assets.length > 1) {
            return this.assets.map(asset => `${asset.asset} (${asset.weight}%)`).join(', ');
        } else {
            return this.assets[0].asset;
        }
    }
}

export class LineType extends BaseObject {
    line_type: string;
    color: string;
    benchmark?: { asset: string };

    constructor(
        line_type: string,
        color: string,
        assets?: { asset: string, weight: number }[],
        name?: string,
        benchmark?: { asset: string },
    ) {
        super(assets, name);
        this.line_type = line_type;
        this.color = color;
        this.benchmark = benchmark;
    }
}

export class TableObject extends BaseObject {
    excess_returns: boolean;

    constructor(
        assets?: { asset: string, weight: number }[],
        name?: string,
        excess_returns?: boolean,
    ) {
        super(assets, name);
        this.excess_returns = excess_returns !== undefined ? excess_returns : this.shouldHaveExcessReturns(this.assets);
    }

    private shouldHaveExcessReturns(assets: { asset: string, weight: number }[]): boolean {
        return assets.length === 1 && !assets[0].asset.startsWith("F-") && !assets[0].asset.startsWith("SIM_");
    }
}

// Defining Form and Defaults
export interface FormType {
    plot_objects: LineType[];
    settings: SettingsFormType;
    plot_type: string; 
    theme: ThemeType;
}

// Defining Form and Defaults
export interface InvestmentGrowth extends FormType {
    plot_type: 'Investment Growth';
    start_val: number;
}
export interface RollingVol extends FormType {
    plot_type: 'Rolling Volatility';
    window: number;
}
export interface RollingCorrel extends FormType {
    plot_type: 'Rolling Correlation';
    window: number;
    benchmark: string
}
export interface RollingReturns extends FormType {
    plot_type: 'Rolling Returns';
    window: number;
}
export interface MaxDrawdown extends FormType {
    plot_type: 'Max Drawdown';
}

export const defaultInvestmentGrowth: InvestmentGrowth = {
    plot_objects: [],
    settings: defaultInvestmentGrowthSettings,
    theme: plottingThemes["ReSolve"],
    plot_type: 'Investment Growth',
    start_val: 100,
}
export const defaultRollingVol: RollingVol = {
    plot_objects: [],
    settings: defaultRollingVolatilitySettings,
    theme: plottingThemes["ReSolve"],
    plot_type: 'Rolling Volatility',
    window: 60,
}
export const defaultRollingCorrel: RollingCorrel = {
    plot_objects: [],
    settings: defaultRollingCorrelationSettings,
    theme: plottingThemes["ReSolve"],
    plot_type: 'Rolling Correlation',
    window: 252,
    benchmark: "IVV",
}
export const defaultRollingReturns: RollingReturns = {
    plot_objects: [],
    settings: defaultRollingReturnsSettings,
    theme: plottingThemes["ReSolve"],
    plot_type: 'Rolling Returns',
    window: 252,
}
export const defaultMaxDrawdown: MaxDrawdown = {
    plot_objects: [],
    settings: defaultMaxDrawdownSettings,
    theme: plottingThemes["ReSolve"],
    plot_type: 'Max Drawdown',
}


// Performance table form and Defaults
export interface PerformanceTable {
    plot_objects: TableObject[];
    settings: SettingsFormType;
    plot_type: string; 
    theme: ThemeType;
}
const defaultPerformanceTable: PerformanceTable = {
    plot_objects: [],
    settings: defaultPerformanceTableSettings,
    plot_type: 'Performance Table',
    theme: plottingThemes["ReSolve"]
}


// Defining Form State
interface FormStateType {
    form: InvestmentGrowth| MaxDrawdown | RollingCorrel | RollingReturns | RollingVol;
    setForm: (updater: (prevForm: InvestmentGrowth| MaxDrawdown | RollingCorrel | RollingReturns | RollingVol) => InvestmentGrowth| MaxDrawdown | RollingCorrel | RollingReturns | RollingVol) => void;
    performanceTable: PerformanceTable;
    setPerformanceTable: (updater: (prevPerformanceTable: PerformanceTable) => PerformanceTable) => void;
}

const defaultFormState: FormStateType = {
    form: defaultInvestmentGrowth, 
    setForm: () => {},
    performanceTable: defaultPerformanceTable,
    setPerformanceTable: () => {},
};

export const FormContext = createContext<FormStateType>(defaultFormState);
export const useFormContext = () => useContext(FormContext);


// Function to convert LineType objects to TableObject objects
const convertLineTypesToTableObjects = (lineTypes: LineType[]): TableObject[] => {
    return lineTypes.map(lineType => new TableObject(lineType.assets, lineType.name));
};

interface FormContainerProps {
    children: ReactNode;
}
export const FormContainer: React.FC<FormContainerProps> = ({ children }) => {
    const [form, setForm] = useState<InvestmentGrowth| MaxDrawdown | RollingCorrel | RollingReturns | RollingVol>(defaultFormState.form);
    const [performanceTable, setPerformanceTable] = useState<PerformanceTable>(defaultPerformanceTable);

    // Effect to sync form.plot_objects with performanceTable.plot_objects
    useEffect(() => {
        setPerformanceTable(prevPerformanceTable => ({
            ...prevPerformanceTable,
            plot_objects: convertLineTypesToTableObjects(form.plot_objects)
        }));
    }, [form.plot_objects]);

    // Effect to sync form.settings.date_settings with performanceTable.settings.date_settings
    useEffect(() => {
        setPerformanceTable(prevPerformanceTable => ({
            ...prevPerformanceTable,
            settings: {
                ...prevPerformanceTable.settings,
                date_settings: form.settings.date_settings
            }
        }));
    }, [form.settings.date_settings]);

    useEffect(() => {
        console.log('Form changed:', form);
    }, [form]);

    useEffect(() => {
        console.log('Performance Table changed:', performanceTable);
    }, [performanceTable]);

    return (
        <FormContext.Provider value={{ form, setForm, performanceTable, setPerformanceTable }}>
            {children}
        </FormContext.Provider>
    );
};