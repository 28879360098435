import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';

const TextInput = ({ label, value, onChange }) => {
  const theme = useTheme();

  return (
      <TextField
        label={label}
        variant="outlined"
        fullWidth
        value={value}
        onChange={onChange}
        InputProps={{
          sx: {
            bgcolor: theme.palette.surfaces.darker,
            color: theme.palette.getContrastText(theme.palette.background.default),
          },
        }}
      />
  );
};

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TextInput;
