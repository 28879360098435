export const plottingApiUrl = `${process.env.REACT_APP_BACKEND_ENDPOINT}:8765/plotting/`

export type SettingsFormType = {
  [key: string]: {
    [subKey: string]: any;
  };
};

export const defaultMaxDrawdownSettings: SettingsFormType = {
  "title_settings": {
    "title_text": undefined,
    "title_font_size": 22,
    "title_justify": 0.5,
    "subtitle_text": undefined,
    "subtitle_font_size": 18,
    "subtitle_justify": 0.5,
    "caption_text": "Past performance is not indicative of future results.",
    "caption_font_size": 14,
    "caption_justify": 0,
    "caption_width": 165,
    "show_date_range": false,
  },
  "chart_settings": {
    "figure_height": 800,
    "figure_width": 2000,
    "panel_border": false,
    "show_grid": false,
  },
  "date_settings": {
    "start_date": '1990-01-01',
    "end_date": new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0],
  },
  "axis_settings": {
    "x_axis_text": undefined,
    "x_axis_font_size": 12,
    "y_axis_text": undefined, 
    "y_axis_font_size": 12,
    "y_axis_log2": false,
    "x_axis_label_angle": 45,
    "axis_thickness": 5,
    "axis_color": '#000000',
    "y_axis_max": undefined,
    "y_axis_min": undefined,
    "x_axis_date_format": 'auto',
    "y_axis_format": ".0%"
  },
  "legend_settings": {
    "legend_position": 'top',
    "legend_size": 16,
    "legend_direction": 'Horizontal',
  },
  "horizontal_line_settings": {
    "horizontal_line_type": 'Dashed',
    "horizontal_line_color": '#000000',
    "horizontal_line_size": 1,
  },
}

export const defaultRollingVolatilitySettings: SettingsFormType = {
  "title_settings": {
    "title_text": undefined,
    "title_font_size": 22,
    "title_justify": 0.5,
    "subtitle_text": undefined,
    "subtitle_font_size": 18,
    "subtitle_justify": 0.5,
    "caption_text": "Past performance is not indicative of future results.",
    "caption_font_size": 14,
    "caption_justify": 0,
    "caption_width": 165,
    "show_date_range": false,
  },
  "chart_settings": {
    "figure_height": 800,
    "figure_width": 2000,
    "panel_border": false,
    "show_grid": false,
  },
  "date_settings": {
    "start_date": '1990-01-01',
    "end_date": new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0],
  },
  "axis_settings": {
    "x_axis_text": undefined,
    "x_axis_font_size": 12,
    "y_axis_text": undefined, 
    "y_axis_font_size": 12,
    "y_axis_log2": false,
    "x_axis_label_angle": 45,
    "axis_thickness": 5,
    "axis_color": '#000000',
    "y_axis_max": undefined,
    "y_axis_min": undefined,
    "x_axis_date_format": 'auto',
    "y_axis_format": ".0%"
  },
  "legend_settings": {
    "legend_position": 'top',
    "legend_size": 16,
    "legend_direction": 'Horizontal',
  },
  "horizontal_line_settings": {
    "horizontal_line_type": 'Dashed',
    "horizontal_line_color": '#000000',
    "horizontal_line_size": 1,
  },
}

export const defaultRollingReturnsSettings: SettingsFormType = {
  "title_settings": {
    "title_text": undefined,
    "title_font_size": 22,
    "title_justify": 0.5,
    "subtitle_text": undefined,
    "subtitle_font_size": 18,
    "subtitle_justify": 0.5,
    "caption_text": "Past performance is not indicative of future results.",
    "caption_font_size": 14,
    "caption_justify": 0,
    "caption_width": 165,
    "show_date_range": false,
  },
  "chart_settings": {
    "figure_height": 800,
    "figure_width": 2000,
    "panel_border": false,
    "show_grid": false,
  },
  "date_settings": {
    "start_date": '1990-01-01',
    "end_date": new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0],
  },
  "axis_settings": {
    "x_axis_text": undefined,
    "x_axis_font_size": 12,
    "y_axis_text": undefined, 
    "y_axis_font_size": 12,
    "y_axis_log2": false,
    "x_axis_label_angle": 45,
    "axis_thickness": 5,
    "axis_color": '#000000',
    "y_axis_max": undefined,
    "y_axis_min": undefined,
    "x_axis_date_format": 'auto',
    "y_axis_format": ".0%"
  },
  "legend_settings": {
    "legend_position": 'top',
    "legend_size": 16,
    "legend_direction": 'Horizontal',
  },
  "horizontal_line_settings": {
    "horizontal_line_type": 'Dashed',
    "horizontal_line_color": '#000000',
    "horizontal_line_size": 1,
    "place_horizontal_line_at": "0",
  },
}

export const defaultRollingCorrelationSettings: SettingsFormType = {
  "title_settings": {
    "title_text": undefined,
    "title_font_size": 22,
    "title_justify": 0.5,
    "subtitle_text": undefined,
    "subtitle_font_size": 18,
    "subtitle_justify": 0.5,
    "caption_text": "Past performance is not indicative of future results.",
    "caption_font_size": 14,
    "caption_justify": 0,
    "caption_width": 165,
    "show_date_range": false,
  },
  "chart_settings": {
    "figure_height": 800,
    "figure_width": 2000,
    "panel_border": false,
    "show_grid": false,
  },
  "date_settings": {
    "start_date": '1990-01-01',
    "end_date": new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0],
  },
  "axis_settings": {
    "x_axis_text": undefined,
    "x_axis_font_size": 12,
    "y_axis_text": undefined, 
    "y_axis_font_size": 12,
    "y_axis_log2": false,
    "x_axis_label_angle": 45,
    "axis_thickness": 5,
    "axis_color": '#000000',
    "y_axis_max": undefined,
    "y_axis_min": undefined,
    "x_axis_date_format": 'auto',
    "y_axis_format": ".0%"
  },
  "legend_settings": {
    "legend_position": 'top',
    "legend_size": 16,
    "legend_direction": 'Horizontal',
  },
  "horizontal_line_settings": {
    "horizontal_line_type": 'Dashed',
    "horizontal_line_color": '#000000',
    "horizontal_line_size": 1,
    "place_horizontal_line_at": "0",
  },
}


export const defaultInvestmentGrowthSettings: SettingsFormType = {
  "title_settings": {
    "title_text": undefined,
    "title_font_size": 22,
    "title_justify": 0.5,
    "subtitle_text": undefined,
    "subtitle_font_size": 18,
    "subtitle_justify": 0.5,
    "caption_text": "Past performance is not indicative of future results.",
    "caption_font_size": 14,
    "caption_justify": 0,
    "caption_width": 165,
    "show_date_range": false,
  },
  "chart_settings": {
    "figure_height": 800,
    "figure_width": 2000,
    "panel_border": false,
    "show_grid": false,
  },
  "date_settings": {
    "start_date": '1990-01-01',
    "end_date": new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0],
  },
  "axis_settings": {
    "x_axis_text": undefined,
    "x_axis_font_size": 12,
    "y_axis_text": undefined, 
    "y_axis_font_size": 12,
    "y_axis_log2": true,
    "x_axis_label_angle": 45,
    "axis_thickness": 5,
    "axis_color": '#000000',
    "y_axis_max": undefined,
    "y_axis_min": undefined,
    "x_axis_date_format": 'auto',
  },
  "legend_settings": {
    "legend_position": 'top',
    "legend_size": 16,
    "legend_direction": 'Horizontal',
  },
  "horizontal_line_settings": {
    "horizontal_line_type": 'Dashed',
    "horizontal_line_color": '#000000',
    "horizontal_line_size": 1,
    "place_horizontal_line_at": "100",
  },
}

export const defaultPerformanceTableSettings: SettingsFormType = {
  "date_settings": {
    "start_date": '1990-01-01',
    "end_date": new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0],
  },
  "performance_table_settings": {
    "performance_metrics": ['Inception Date', 'Annualized Return', 'Annualized Volatility', 'Annualized Sharpe', 'Maximum Drawdown', 'Annualized Sortino', 'Average Drawdown', 'Best Day', 'Worst Day', 'Best Month', 'Worst Month', 'Best Year', 'Worst Year'],
    "row_height": 26, 
    "table_width": 2000,
    "header_color": undefined,
    "font_family": undefined,
    "font_size": 12,
  },
}


