import { useFormContext } from '../contexts/FormContext';
import { FormType, PerformanceTable } from '../contexts/FormContext';

export const useSettingsManager = () => {
    const { setForm, setPerformanceTable } = useFormContext();

    const updateLinePlotSettings = (
        group: string, 
        key: string,
        newValue: any,
    ) => {
        setForm((prevForm) => {
            const updatedGroup = {
                ...prevForm.settings[group],
                [key]: newValue, 
            };
            return {
                ...prevForm,
                settings: {
                    ...prevForm.settings,
                    [group]: updatedGroup,
                },
            };
        });
    };

    const updatePerformanceTableSettings = (
        group: string, 
        key: string,
        newValue: any,
    ) => {
        setPerformanceTable((prevPerformanceTable: PerformanceTable) => {
            const updatedGroup = {
                ...prevPerformanceTable.settings[group],
                [key]: newValue, 
            };
            return {
                ...prevPerformanceTable,
                settings: {
                    ...prevPerformanceTable.settings,
                    [group]: updatedGroup,
                },
            };
        });
    };


    return { 
        updateLinePlotSettings, 
        updatePerformanceTableSettings,
    };
};