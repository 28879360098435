import React from 'react';
import { BaseSettingsPanelProps } from './base_settings_pannel';
import { RAMTextField, RAMFormControl } from './base_settings';
import { FormControlLabel, Switch, InputLabel, Select, MenuItem, Typography, Box } from '@mui/material';
import { SettingsFormType } from '../../../types/plotting_backend';

interface AxisSettingsProps extends BaseSettingsPanelProps {
    axis_settings: SettingsFormType['axis_settings']
}

const AxisSettings: React.FC<AxisSettingsProps> = ({axis_settings, onChange}) => {

    const xAxisLabelAngleOptions = [
        { label: '0°', value: 0 },
        { label: '45°', value: 45 },
        { label: '90°', value: 90 },
    ];

    const xAxisDateFormatOptions = [
        { label: 'Auto', value: 'auto' },
        { label: 'MMMM DD, YYYY', value: '%B %d, %Y' },
        { label: 'MM-DD-YYYY', value: '%m-%d-%Y' },
        { label: 'MMMM YYYY', value: '%B %Y' },
        { label: 'MM-YYYY', value: '%m-%Y' },
        { label: 'YYYY', value: '%Y' },
    ];

    return (
        <Box display="flex" flexDirection="column" gap="15px">
            <RAMTextField 
                label={"X-Axis Label"}
                value={axis_settings.x_axis_text || ''}
                onChange={(event) => onChange("axis_settings", "x_axis_text", event.target.value)}
                size="small"
            />
            <RAMTextField 
                label={"X-Axis Size"}
                value={axis_settings.x_axis_font_size || ''}
                onChange={(event) => onChange("axis_settings", "x_axis_font_size", event.target.value)}
                size="small"
            />
            <RAMTextField 
                label={"Y-Axis Label"}
                value={axis_settings.y_axis_text || ''}
                onChange={(event) => onChange("axis_settings", "y_axis_text", event.target.value)}
                size="small"
            />
            <RAMTextField 
                label={"Y-Axis Size"}
                value={axis_settings.y_axis_font_size || ''}
                onChange={(event) => onChange("axis_settings", "y_axis_font_size", event.target.value)}
                size="small"
            />
            <RAMTextField 
                label={"Y-Axis Min"}
                value={axis_settings.y_axis_max || ''}
                onChange={(event) => onChange("axis_settings", "y_axis_max", event.target.value)}
                size="small"
            />
            <RAMTextField 
                label={"Y-Axis Max"}
                value={axis_settings.y_axis_min || ''}
                onChange={(event) => onChange("axis_settings", "y_axis_min", event.target.value)}
                size="small"
            />
            <RAMTextField 
                label={"Axis Thickness"}
                value={axis_settings.axis_thickness || ''}
                onChange={(event) => onChange("axis_settings", "axis_thickness", event.target.value)}
                size="small"
            />
            <RAMTextField 
                label={"Axis Color"}
                value={axis_settings.axis_color || ''}
                onChange={(event) => onChange("axis_settings", "axis_color", event.target.value)}
                size="small"
            />
            <RAMFormControl size="small">
                <InputLabel>{"X-Axis Label Angle"}</InputLabel>
                <Select
                    value={axis_settings.x_axis_label_angle}
                    onChange={(event) => onChange("axis_settings", "x_axis_label_angle", event.target.value)}
                    label={"X-Axis Label Angle"}
                >
                    {xAxisLabelAngleOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </RAMFormControl>
            <RAMFormControl size="small" >
                <InputLabel>{"X-Axis Date Format"}</InputLabel>
                <Select
                    value={axis_settings.x_axis_date_format}
                    onChange={(event) => onChange("axis_settings", "x_axis_date_format", event.target.value)}
                    label={"X-Axis Date Format"}
                >
                    {xAxisDateFormatOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </RAMFormControl>
            <FormControlLabel
                sx={{px:1, width: "fit-content"}}
                control={
                    <Switch
                        checked={axis_settings.y_axis_log2}
                        onChange={(event) => onChange("axis_settings", "y_axis_log2", event.target.checked)}
                        size="small"
                    />
                }
                label={<Typography sx={{ fontSize: 12 }} style={{ width: '100%' }}>Y-Axis Logarithmic Scale</Typography>}
            />
        </Box> 
    );
};

AxisSettings.displayName = 'Axis Settings';

export default AxisSettings;