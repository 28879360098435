import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { downloadPlotFile } from '../utils/requestUtils';

interface ExportManagerProps {
    disabled?: boolean
    form: any,
    variant?: "text" | "outlined" | "contained",
}

const ExportManager: React.FC<ExportManagerProps> = ({disabled = false, form, variant = "contained"}) => {
    const { getAccessTokenSilently } = useAuth0();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl)

    const downloadPlot = async (fileType: string) => {
        const token = await getAccessTokenSilently();
        await downloadPlotFile(form, fileType, token);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleExport = (fileType: string) => {
        handleClose(); 
        downloadPlot(fileType)
    };

    return (
        <>
            <Button
                onClick={handleClick}
                variant={variant}
                color="primary"
                disabled={disabled}
                sx={{height: "100%"}}
            >
                Export
            </Button>
            <Menu
                id="export-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'export-button',
                }}
            >
                <MenuItem onClick={() => handleExport('pdf')}>PDF</MenuItem>
                <MenuItem onClick={() => handleExport('png')}>PNG</MenuItem>
                <MenuItem onClick={() => handleExport('xlsx')}>XLSX</MenuItem>
            </Menu>
        </>
    );
};

export default ExportManager;