import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

const CustomAccordion = styled(Accordion)(({ theme }) => ({
    '& .MuiAccordionSummary-root': {
        backgroundColor: theme.palette.background.paper,
        minHeight:"40px",
        padding: "0 10px"
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
        borderTop: `1px solid ${theme.palette.divider}`,
    },
    '& .MuiAccordionDetails-root': {
        backgroundColor: theme.palette.background.paper,
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
        color: theme.palette.primary.main,
    },   
    '& svg': {
        fontSize: '1.1rem',
    },
}));

interface DynamicAccordionPanelProps {
    components: JSX.Element[];
}

const DynamicAccordionPanel: React.FC<DynamicAccordionPanelProps> = ({ components }) => {
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div>
          {components.map((componentElement, index) => {
            const panelId = `panel${index}a-content`;
            return (
              <CustomAccordion key={index} expanded={expanded === panelId} onChange={handleChange(panelId)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={panelId}
                  id={`panel${index}a-header`}
                >
                  <Typography fontSize={11}>
                    {componentElement.type.displayName || `Setting ${index + 1}`}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {expanded === panelId && componentElement}
                </AccordionDetails>
              </CustomAccordion>
            );
          })}
        </div>
    );
};

export default DynamicAccordionPanel;