import React, { useState, useRef, useEffect } from 'react';
import { Box, Button, Drawer, IconButton, CircularProgress } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import SettingsIcon from '@mui/icons-material/Settings';
import LineAxisIcon from '@mui/icons-material/LineAxis';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import MenuIcon from '@mui/icons-material/Menu';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import AddIcon from '@mui/icons-material/Add';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import BackupTableIcon from '@mui/icons-material/BackupTable';

import { useLinePlotContext } from '../contexts/LinePlotContext';
import { useFormContext } from '../contexts/FormContext';

import SettingsPanel from '../components/SettingsPannel';
import LineManager from '../components/LineManager';
import ThemeManager from '../components/ThemeManager';
import ExportManager from '../components/ExportManager';
import PersistentDrawerLeft from '../components/subcomponents/persistant_drawer/persistant_drawer';
import RamLogo from '../../../common/components/RAMLogo';
import AddAssets from '../components/AddAssets';
import EnterDate from '../components/EnterDate';
import QuickLine from '../components/QuickLine';
import SpeedNav from '../components/SpeedNav';
import PerformanceTableManager from '../components/PerformanceTableManager';
import { generateFontFaceStyle } from '../utils/iframeFontLoader';
import { getPlotFile } from '../utils/requestUtils';

const LinePlot: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { form, performanceTable, setPerformanceTable } = useFormContext();
  const [plotHTML, setPlotHTML] = useState<string>("");
  const [performanceTableHTML, setPerformanceTableHTML] = useState<string>("");
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const boxRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { showPerformanceTable, formValid, linePlotTheme } = useLinePlotContext();
  const resizeTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const fontFaceStyle = generateFontFaceStyle();

  // Removes displayed figures if form becomes invalid
  useEffect(() => {
    if (form.plot_objects.length === 0) {
      setPlotHTML('')
      setPerformanceTableHTML('')
    }
  }, [form.plot_objects.length]);

  // Requests plot if form changes
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (formValid) {
        handleSubmitForm();
      }
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [formValid, form, performanceTable]);

  useEffect(() => {
    if (!showPerformanceTable) {
      setPerformanceTableHTML('')
      setPlotHTML('')
    } else {
      setPlotHTML('')
    }
    if (formValid) {
      handleSubmitForm();
    }
  }, [showPerformanceTable]);


  const handleSubmitForm = async () => {
    if (!formValid) {
      return
    }
    setLoading(true);
    const iframe = iframeRef.current;
    const newWidth = iframe?.clientWidth || form.settings.chart_settings.figure_width;
    const newHeight = iframe?.clientHeight || form.settings.chart_settings.figure_height;

    console.log("form", form)

    const updatedForm = {
      ...form,
      settings: {
        ...form.settings,
        chart_settings: {
          ...form.settings.chart_settings,
          figure_width: newWidth,
          figure_height: newHeight
        }
      }
    };
    updatedForm.theme = linePlotTheme;
    console.log("updated form", updatedForm)

    const token = await getAccessTokenSilently();
    const plotHTML = await getPlotFile(updatedForm, token);
    setPlotHTML(plotHTML);
   
    if (showPerformanceTable) {
      const updatedPerformanceTable = {
        ...performanceTable,
        settings: {
          ...performanceTable.settings,
          performance_table_settings: {
            ...performanceTable.settings.performance_table_settings,
            table_width: "100%",
          }
        }
      };
      updatedPerformanceTable.theme = linePlotTheme;

      const token = await getAccessTokenSilently();
      const performanceTableHTML = await getPlotFile(updatedPerformanceTable, token);
      setPerformanceTableHTML(performanceTableHTML);
    };
    setLoading(false);
  };

  useEffect(() => {
    const box = boxRef.current;
    if (!box || !formValid) return;
  
    let lastWidth = box.clientWidth;
    let lastHeight = box.clientHeight;
  
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect;
        if ((width !== lastWidth || height !== lastHeight) && formValid) {
          lastWidth = width;
          lastHeight = height;
          if (resizeTimeoutRef.current) {
            clearTimeout(resizeTimeoutRef.current);
          }
          resizeTimeoutRef.current = setTimeout(() => {
            console.log("submitting for resizing");
            handleSubmitForm();
          }, 500); 
        }
      }
    });
   
    resizeObserver.observe(box);
  
    return () => {
      resizeObserver.disconnect();
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }
    };
  }, [formValid, form, performanceTable, showPerformanceTable]); 


  const drawerContents = [
    {component: <SettingsPanel/>, name: "Settings", icon: SettingsIcon},
    {component: <LineManager />, name: "Lines", icon: LineAxisIcon},
    {component: <ThemeManager />, name: "Theme", icon: FormatPaintIcon},
    {component: <PerformanceTableManager />, name: "Performance Table", icon: BackupTableIcon},
  ]

  const quickActions = [
    {icon: <AddIcon />, name: 'Add Assets', content: <AddAssets onClose={handleSubmitForm}/>},
    {icon: <PlaylistAddIcon />, name: 'Add Portfolio', content: <QuickLine onClose={handleSubmitForm}/>},
    {icon: <CalendarMonthIcon />, name: 'Set Date', content: <EnterDate />},
    
  ];

  const [performanceTableHeight, setPerformanceTableHeight] = useState<number>(400);

  useEffect(() => {
    const newHeight = performanceTable.settings.performance_table_settings.row_height * performanceTable.settings.performance_table_settings.performance_metrics.length + 35;
    setPerformanceTableHeight(Math.max(newHeight, 50));
  }, [performanceTable.settings.performance_table_settings.performance_metrics.length]);

  return (
    <>
      <PersistentDrawerLeft setOpen={setDrawerOpen} open={drawerOpen} drawerContents={drawerContents}>
          <Drawer anchor="top" variant='permanent'>
            <Box display="flex" alignItems="center" justifyContent="space-between" height="50px" sx={{px: 1}}>
              <Box width="200px" display="flex">
                <IconButton sx={{p: 0.5}} onClick={() => setDrawerOpen(!drawerOpen)} >
                  <MenuIcon sx={{ fontSize:"20px"}}/>
                </IconButton>
                <RamLogo sx={{paddingLeft: 1}} width="100px"/>
              </Box>
              <Box display="flex">
                <ExportManager variant="text" disabled={!formValid} form={form}/>
              </Box>
            </Box>
          </Drawer>
          <Box ref={boxRef} height="calc(100% - 50px)" display="flex" justifyContent="center" flexDirection="column" alignContent="center" sx={{ backgroundColor:"white", marginTop: "50px" }}>
            {loading && (
              <CircularProgress
                size={68}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-34px',
                  marginLeft: '-34px',
                }}
              />
            )}
            <iframe 
              ref={iframeRef} 
              srcDoc={`${fontFaceStyle}${plotHTML}`}
              style={{ 
                width: `100%`, 
                border: 'none', 
                maxWidth: "100%",
                maxHeight: "100%",
                overflow: "hidden",
                flex: '1 1 auto',
                
              }} 
              scrolling="no"
            />
            { showPerformanceTable && (
              <iframe 
                srcDoc={`${fontFaceStyle}${performanceTableHTML}`} 
                style={{ 
                  width: `calc(100% - 30px)`, 
                  minHeight: `${performanceTableHeight}px`, 
                  margin: "0 15px",
                  border: 'none', 
                  maxWidth: "100%",
                  maxHeight: "100%",
                  
                  overflow: "hidden",
                  flex: 0,
                }} 
                
              />
            )}
        </Box>
        <SpeedNav actions={quickActions}/>
    </PersistentDrawerLeft>
  </>
  );
};

export default LinePlot;