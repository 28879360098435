import React, { useState, useCallback } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Box, Drawer, Divider, Stack, Tooltip, IconButton } from '@mui/material';

const drawerWidth = 400;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft({ children, drawerContents, open, setOpen }) {
  const theme = useTheme();
  const [activeDrawerIndex, setActiveDrawerIndex] = useState(0);
  const [width, setWidth] = useState(drawerWidth);


  const startResizing = useCallback((mouseDownEvent) => {
    mouseDownEvent.preventDefault();
    let startWidth = width;
    const startPositionX = mouseDownEvent.clientX;
  
    const dragger = document.getElementById('dragger');
    if (dragger) {
      dragger.style.width = '100vw';
      dragger.style.position = 'fixed';
      dragger.style.zIndex = '1500'; 
    }
  
    const handleMouseMove = (mouseMoveEvent) => {
      mouseMoveEvent.preventDefault();
      const currentWidth = startWidth + (mouseMoveEvent.clientX - startPositionX);
      setWidth(Math.max(240, currentWidth));
    };
  
    const handleMouseUp = () => {
      if (dragger) {
        dragger.style.width = '5px';
        dragger.style.zIndex = '1500';
        dragger.style.position = 'absolute';
      }
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  }, [width]);
  
  
  return (
    <Box sx={{ height: "100vh", display: 'flex'}}>
      <Box position="relative">
        <Drawer
          sx={{
            overflow: "hidden",
            top: "50px",
            width: open ? width : 0,
            flexShrink: 0,
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.easeInOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
            '& .MuiDrawer-paper': {
              width: width,
              boxSizing: 'border-box',
              top: "50px",
              transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.easeInOut,
                duration: theme.transitions.duration.enteringScreen,
              }),
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <Box display="flex" height="calc(100% - 50px)" >
            <Box display="flex" justifyContent="center" alignItems="flex-start" flexGrow={0} >
              <Stack>
              {drawerContents.map((content, index) => (
                <Tooltip title={content.name} key={index} placement="right">
                  <Box>
                    <IconButton
                      onClick={() => setActiveDrawerIndex(index)}
                      color={activeDrawerIndex === index ? "primary" : "default"} 
                    >
                      {content.icon ? React.createElement(content.icon, { sx: { fontSize: "20px" } }) : null}
                    </IconButton>
                  </Box>
                </Tooltip>
              ))}
              </Stack>
              <Divider orientation="vertical"/>
            </Box>
            <Box flexGrow={1}  overflow="auto">
              {drawerContents[activeDrawerIndex].component}
            </Box>
          </Box>
        </Drawer>
        <Box
          id="dragger"
          sx={{
            width: '5px',
            height: '100%',
            cursor: 'ew-resize',
            position: 'absolute',
            right: 0, 
            top: 0,
            backgroundColor: 'transparent',
            zIndex: 1500,
          }}
          onMouseDown={startResizing}
        /> 
      </Box>
      <Main sx={{p:0, m:0}} open={open} >
        {children}
      </Main>
    </Box>    
  );
}