import React, { useState } from 'react';
import { useLineManager } from '../hooks/useLineManager';
import { Box, Stack, TextField, Button, Typography, MenuItem, Select, InputLabel } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useLinePlotContext } from '../contexts/LinePlotContext';
import { RAMFormControl, RAMAutoComplete, RAMTextField } from './subcomponents/CustomInputs';
import { useFormContext } from '../contexts/FormContext';


const QuickLine = ({onClose}) => {
    const { handleAddLine, handleCreateNewLine } = useLineManager();
    const { form } = useFormContext();
    const { linePlotTheme, defaultLineType, dataOptions } = useLinePlotContext();
    const [color, setColor] = useState(linePlotTheme.colors[form.plot_objects.length % linePlotTheme.colors.length])
    const [displayName, setDisplayName] = useState();
    const [assetWeights, setAssetWeights] = useState([{}]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const newLine = handleCreateNewLine(defaultLineType, undefined, assetWeights, displayName || undefined)
        handleAddLine(newLine)
        console.log(`Added new portfolio line: ${newLine}`)
        if (onClose) {
            await onClose();
        }
    };
    const handleAddAssetWeight = () => {
        setAssetWeights([...assetWeights, {}]);
    };

    const handleUpdateAssetWeight = (index, asset, weight) => {
        const updatedAssetWeights = [...assetWeights];
        updatedAssetWeights[index] = { asset: asset, weight: weight };
        setAssetWeights(updatedAssetWeights);
    };


    return (
        <Box width="500px">
            <form onSubmit={handleSubmit}>
                <Stack gap={1}>
                    <Box display="flex" justifyContent="space-between">
                        <RAMTextField
                            sx={{ minWidth: "150px" }}
                            label="Display Name"
                            value={displayName}
                            onChange={(event) => setDisplayName(event.target.value)}
                            size="small"
                        />
                        <RAMFormControl>
                            <InputLabel>Color</InputLabel>
                            <Select
                                value={color}
                                onChange={(event) => setColor(event.target.value)}
                                label="Color"
                            >
                                {linePlotTheme.colors.map((color) => (
                                    <MenuItem key={color} value={color}>
                                        <Box width="30px" height="18px" sx={{backgroundColor: color}}></Box>
                                    </MenuItem>
                                ))}
                            </Select>
                        </RAMFormControl>
                    </Box>
                    <Stack gap={1}>
                        {assetWeights.map((pair, index) => {
                            const asset = pair.asset || '';
                            const weight = pair.weight || '';
                            return (
                                <Box key={index} display="flex" gap={2} alignItems="center">
                                    <RAMAutoComplete
                                        disablePortal
                                        id={`combo-box-demo-${index}`}
                                        options={dataOptions}
                                        value={asset}
                                        onChange={(event, newValue) => handleUpdateAssetWeight(index, newValue || '', weight)}
                                        fullWidth
                                        renderInput={(params) => <TextField required {...params} label="Asset" />}
                                    />
                                    <RAMTextField
                                        required
                                        label="Weight"
                                        value={weight}
                                        onChange={(e) => handleUpdateAssetWeight(index, asset, e.target.value)}
                                        size="small"
                                    />
                                </Box>
                            );
                        })}
                    </Stack>
                    <Box display="flex" gap={2} alignItems="center" justifyContent="center">
                        <Button
                            fullWidth
                            sx={{ height: "30px" }}
                            onClick={handleAddAssetWeight}
                            aria-label="add movie-weight"
                            startIcon={<AddIcon fontSize='20px'/>}
                        >
                            <Typography fontSize="10px">Add Asset</Typography>
                        </Button>
                        <Button type="submit" sx={{height: "30px", py: 0, maxWidth: "80px", minWidth: "80px"}} variant="outlined">
                            <Typography fontSize="10px">Done</Typography>
                        </Button>
                    </Box>
                </Stack>
            </form>
        </Box>
    );
}

export default QuickLine;