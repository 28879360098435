export const generateFontFaceStyle = (): string => {
    return `
      <style>
        @font-face {
        font-family: "DM Sans";
        src: local("DM Sans"), url(./fonts/DM_Sans.ttf) format("truetype");
          font-weight: normal;
          font-style: normal;
        }
        @font-face {
        font-family: "Helvetica Neue";
        src: local("Helvetica Neue"), url(./fonts/Helvetica Neue.ttf) format("truetype");
          font-weight: normal;
          font-style: normal;
        }
      </style>
    `;
  };