import { Button, Stack } from '@mui/material';
import SourceOutlinedIcon from '@mui/icons-material/SourceOutlined';

interface Template {
    name: string
}

interface StartMenuProps {
  template: Template
}

const DocumentIconLarge = ({ template }: StartMenuProps) => {
    return (
        <div style={{ backgroundColor: "transparent", maxWidth: "60px" }}>
            <Button>
                <Stack alignItems="center">
                    <SourceOutlinedIcon style={{ fontSize: '30px', color: "#5f94c5", fontWeight: "50", paddingBottom: "5px", }}/>
                    <span style={{
                        color: "white", 
                        textTransform: "none", 
                        fontSize:"8pt", 
                        lineHeight: "1.2",
                        fontWeight: "150"
                    }}>
                        {template.name}
                    </span>
                </Stack>
            </Button>
        </div>
    );
};

export default DocumentIconLarge;