import React from 'react';

const BetaIcon = () => {
    const betaTagStyle = {
        backgroundColor: "rgb(137 224 255)",
        borderRadius: "13px",
        padding: "6px 13px",
        fontSize: "0.8rem",
        display: "inline-block",
        color: "#252525",
    };

    const tooltipText = "This app is still in development. It may not have all its features yet.";

    return (
        <div style={betaTagStyle} title={tooltipText}>
            Beta
        </div>
    );
};

export default BetaIcon;
