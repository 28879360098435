import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import RDoc from '../../types/RDoc';
import { useAuth0 } from '@auth0/auth0-react';

interface FileActionMenuProps {
    rDoc: RDoc;
    refreshDocuments: () => void;
    setSaveTime: (setSaveTime: string ) => void;
}

const FileActionMenu: React.FC<FileActionMenuProps> = ({ rDoc, refreshDocuments, setSaveTime }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSave = async () => {
        try {
            const iframeDocument = document.querySelector('iframe.document') as HTMLIFrameElement;
            if (iframeDocument && iframeDocument.contentDocument) {
                const htmlTxt = iframeDocument.contentDocument.documentElement.outerHTML;
                const htmlAsset = rDoc.assets.find(asset => asset.type === '.html');
                if (htmlAsset) {
                    const newBlob = new Blob([htmlTxt], { type: 'text/html' });
                    const newFile = new File([newBlob], htmlAsset.filename, { type: 'text/html' });
                    htmlAsset.file = newFile;

                    const token = await getAccessTokenSilently();
                    await htmlAsset.save(token);
                
                    alert('Document has been saved.');
                    const saveTime = new Date().toLocaleTimeString();
                    setSaveTime(saveTime);
                }
            } else {
                alert("Return to the document before saving")
            }
        } catch (error) {
            console.error('Failed to save document:', error);
            alert('Failed to save document.');
        }
    };

    const handleSaveAs = async () => {
        try {
            const newName = prompt(`Please enter a name:`);
            if (newName) {
                await handleSave();
                const token = await getAccessTokenSilently();
                await rDoc.saveCopy(newName, token);
                const saveTime = new Date().toLocaleTimeString()
                setSaveTime(saveTime)
                refreshDocuments();
            }
        } catch (error) {
            console.error('Error creating new document:', error);
        }
    };

    const handleDocumentRename = async (document: RDoc) => {
        const token = await getAccessTokenSilently();
        if (document) {
            try {
                const newName = prompt(`Please enter a name:`);
                if (newName) {
                    await document.rename(newName, token)
                    refreshDocuments(); 
                }
            } catch (error) {
                console.error('Error renaming document:', error);
            }
        }
    };

    return (
        <div>
            <Button
                aria-controls="asset-menu"
                aria-haspopup="true"
                onClick={handleClick}
                style={{fontSize: "9pt", color: "white"}}
            >
                File
            </Button>
            <Menu
                id="asset-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={async () => {handleDocumentRename(rDoc)}}>Rename</MenuItem>
                <MenuItem onClick={() => handleSave()}>Save</MenuItem>
                <MenuItem onClick={() => handleSaveAs()}>Save a Copy</MenuItem>
            </Menu>
        </div>
  );
};
export default FileActionMenu;