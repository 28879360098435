import React from "react";
import { FormContainer } from "../contexts/FormContext";
import { Box  } from '@mui/material';
import LinePlot from "./LinePlot";
import { LinePlotConfigProvider } from "../contexts/LinePlotContext";

const PlottingScreen: React.FC = () => {
    return (
        <Box height="100vh">
            <FormContainer >
                <LinePlotConfigProvider>
                    <LinePlot />
                </LinePlotConfigProvider>
            </FormContainer>
        </Box>
    );
  };
  
export default PlottingScreen;
  