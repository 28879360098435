import React from 'react';
import { MenuItem, Select, InputLabel, Box, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useLinePlotContext } from '../contexts/LinePlotContext';
import { useSettingsManager } from '../hooks/useSettingsManager';
import { RAMFormControl } from './subcomponents/CustomInputs';
import { plottingThemes } from '../types/plotting_themes';



const ThemeManager: React.FC = () => {
    const { linePlotTheme, setLinePlotTheme } = useLinePlotContext();
    const { updateLinePlotSettings } = useSettingsManager();
    const [newColor, setNewColor] = React.useState('#000000');
    const [openColorPicker, setOpenColorPicker] = React.useState(false);

    const handleSetLinePlotTheme = (themeName: string) => {
        const themeObject = plottingThemes[themeName];
        setLinePlotTheme(themeObject);

        if (themeObject.settings) {
            for (const setting of themeObject.settings) {
                updateLinePlotSettings(setting.group.toString(), setting.key.toString(), setting.value);
            }
        }
    };

    const handleClickOpen = () => {
        setOpenColorPicker(true);
    };

    const handleClose = () => {
        setOpenColorPicker(false);
    };

    const handleAddColor = () => {
        const updatedTheme = {
            ...linePlotTheme,
            colors: [...linePlotTheme.colors, newColor]
        };
        setLinePlotTheme(updatedTheme);
        handleClose();
    };

    return (
        <Box padding="5px" paddingTop="10px">
            <RAMFormControl size="small" fullWidth>
                <InputLabel>Plot Theme</InputLabel>
                <Select
                    value={linePlotTheme.theme_name}
                    label="Plot Theme"
                    onChange={(event) => handleSetLinePlotTheme(event.target.value as string)}
                >
                    {Object.keys(plottingThemes).map((theme) => (
                        <MenuItem key={theme} value={theme}>
                            {theme}
                        </MenuItem>
                    ))}
                </Select>
            </RAMFormControl>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '10px' }}>
                {linePlotTheme.colors.map((color, index) => (
                    <div key={index} style={{ 
                        width: '30px', 
                        height: '30px', 
                        backgroundColor: color,
                        border: color === '#000000' ? '1px solid #ffffff' : 'none'
                    }} />
                ))}
            </div>
            <Button onClick={handleClickOpen} style={{ marginTop: '10px' }}>Add Color</Button>
            <Dialog open={openColorPicker} onClose={handleClose}>
                <DialogTitle>Add a Color</DialogTitle>
                <DialogContent>
                    <input 
                        type="color" 
                        value={newColor} 
                        onChange={(e) => setNewColor(e.target.value)} 
                        style={{ width: '100%', height: '50px' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleAddColor}>Add</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ThemeManager;
