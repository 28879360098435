import React from 'react';
import { useLineManager } from '../hooks/useLineManager';
import { Stack, TextField, Button } from '@mui/material';
import { useLinePlotContext } from '../contexts/LinePlotContext';
import { RAMAutoComplete } from './subcomponents/CustomInputs';
import { useFormContext } from '../contexts/FormContext';

const AddAssets = ({ onClose }) => {
    const { handleAddLine, handleCreateNewLine, getNewLineColor } = useLineManager();
    const { defaultLineType, dataOptions } = useLinePlotContext();
    const [assets, setAssets] = React.useState([]);
    const { form } = useFormContext()

    const handleSubmit = async (event) => {
        event.preventDefault();
        const currentNumberOfLines = form.plot_objects.length;
        console.log(currentNumberOfLines)
        for (let i = 0; i < assets.length; i++) {
            const asset = assets[i];
            console.log(asset);
            const assetWeight = { asset: asset, weight: 100 };
            const color = getNewLineColor(currentNumberOfLines + i);
            const newLine = handleCreateNewLine(defaultLineType, color, [assetWeight]);
            handleAddLine(newLine);
            console.log(`Added new single asset line:\nAsset: ${newLine}`);
        };
        if (onClose) {
            await onClose();
        }
    };

    const handleAssetChange = (event, value, reason) => {
        if (Array.isArray(value)) {
            setAssets(value);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Stack gap={1}>
                <RAMAutoComplete
                    multiple
                    disablePortal
                    id="combo-box-demo"
                    options={dataOptions}
                    value={assets}
                    onChange={handleAssetChange}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Assets" placeholder='Enter Tickers' />}
                />
                <Button type="submit" fullWidth sx={{ height: "30px", py: 0 }} variant="outlined">Done</Button>
            </Stack>
        </form>
    );
}

export default AddAssets;