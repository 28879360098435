import "./App.css";
import AppsHomeScreen from "./screens/AppsHomeScreen";
import PodcastPostScreen from "./apps/podcast_post/screens/PodcastPostScreen";
import DocumentCreatorScreen from "./apps/document_manager/screens/DocumentCreatorScreen";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { theme } from './theme.js';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import PlottingScreen from "./apps/plotting/screens/PlottingScreen";
import { withAuthenticationRequired } from '@auth0/auth0-react';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="app" style={{backgroundColor: theme.palette.background.default}}>
        <Router>
          <Switch>
            <Route exact path="/" component={AppsHomeScreen} />
            <Route exact path="/podcast_post" component={PodcastPostScreen} />
            <Route path="/document_creator" component={DocumentCreatorScreen} />
            <Route exact path="/plotting" component={PlottingScreen} />
          </Switch>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default withAuthenticationRequired(App);