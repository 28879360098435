import { makeRequest, RequestConfig } from "../../../common/utils/requestUtils";

const port = process.env.REACT_APP_PODCAST_POST_PORT;

const requestConfigs: Record<string, RequestConfig> = {
  getSummaryDownloadLink: {
    method: 'POST',
    url: `:${port}/podcast_post`,
    responseType: 'blob'
  }
};

export async function getSummaryDownloadLink(files: File[], podcast_title: string, token: string): Promise<string> {
    const formData = new FormData();

    for (let file of files) {
      formData.append('file', file);
    }

    formData.append('podcast_title', podcast_title);
    const config = requestConfigs.getSummaryDownloadLink;
    const response = await makeRequest<File>(config.method, config.url, token, formData, config.responseType, false);
    if (response.isSuccess) {
        const url = URL.createObjectURL(response.body);
        return url;
    } else {
        alert("Failed to load summary");
        throw new Error("Failed to get summary");
    }
}