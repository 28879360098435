import React from 'react';
import { RAMTextField } from '../CustomInputs';
import { BaseSettingsPanelProps } from './base_settings_pannel';
import { SettingsFormType } from '../../../types/plotting_backend';
import { Box } from '@mui/material';

interface DateSettingsProps extends BaseSettingsPanelProps {
    date_settings: SettingsFormType['date_settings'];
}

const DateSettings: React.FC<DateSettingsProps> = ({date_settings, onChange}) => {

    return (
         <Box display="flex" flexDirection="column" gap="15px">
            <RAMTextField 
                label={"Start Date"}
                value={date_settings.start_date}
                onChange={(event) => onChange("date_settings", "start_date", event.target.value)}
                size="small"
            />
            <RAMTextField 
                label={"End Date"}
                value={date_settings.end_date}
                onChange={(event) => onChange("date_settings", "end_date", event.target.value)}
                size="small"
            />
        </Box>

    );
};

DateSettings.displayName = 'Date Settings';
export default DateSettings;