import React, { useState } from 'react';
import { Checkbox, Typography, Grid, Stack, Select, InputLabel, Box, MenuItem } from '@mui/material/';
import { BaseSettingsPanelProps } from './base_settings_pannel';
import { SettingsFormType } from '../../../types/plotting_backend';
import { useLinePlotContext } from '../../../contexts/LinePlotContext';
import { RAMFormControl, RAMTextField } from '../CustomInputs';

interface TableSettingsProps extends BaseSettingsPanelProps {
    performance_table_settings: SettingsFormType['performance_table_settings']
}

const TableSettings: React.FC<TableSettingsProps> = ({performance_table_settings, onChange}) => {
    const { linePlotTheme } = useLinePlotContext();



    return (
        <Box display="flex" flexDirection="column">
            <Box width="100%" gap="10px" display="flex" justifyContent="space-around" paddingBottom="15px">

                {/* TABLE HEADER COLOR */}
                <RAMFormControl >
                    <InputLabel>Header</InputLabel>
                    <Select
                        value={performance_table_settings.header_color || linePlotTheme.colors[0]}
                        onChange={(event) => onChange("performance_table_settings", "header_color", event.target.value)}
                        label="Header Color"
                    >
                        {linePlotTheme.colors.map((color) => (
                            <MenuItem key={color} value={color}>
                                <Box width="30px" height="18px" sx={{backgroundColor: color}}></Box>
                            </MenuItem>
                        ))}
                    </Select>
                </RAMFormControl>

                {/* TABLE ROW HEIGHT */}
                <RAMTextField 
                    label={"Row Height"}
                    value={performance_table_settings.row_height || ''}
                    onChange={(event) => onChange("performance_table_settings", "row_height", event.target.value)}
                    size="small"
                    fullWidth
                />


                {/* TABLE WIDTH */}
                <RAMTextField 
                    label={"Table Width"}
                    value={performance_table_settings.table_width || ''}
                    onChange={(event) => onChange("performance_table_settings", "table_width", event.target.value)}
                    size="small"
                    fullWidth
                />

            </Box>

            <Box width="100%" display="flex" gap="10px" justifyContent="space-around">

                {/* TABLE FONT FAMILY */}
                <RAMFormControl size="small" fullWidth>
                    <InputLabel>{"Font Family"}</InputLabel>
                    <Select
                        value={performance_table_settings.font_family || linePlotTheme.font_family}
                        onChange={(event) => onChange("performance_table_settings", "font_family", event.target.value)}
                        label={"Legend Size"}
                    >
                        {[linePlotTheme.font_family].map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </RAMFormControl>

                {/* TABLE FONT SIZE */}
                <RAMTextField 
                    label={"Font Size"}
                    value={performance_table_settings.font_size || ''}
                    onChange={(event) => onChange("performance_table_settings", "font_size", event.target.value)}
                    style={{width: "100px"}}
                    size="small"
                />
            </Box>
        </Box>
    );
};

TableSettings.displayName = 'Performance Table Settings';
export default TableSettings;