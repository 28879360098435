import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

const FileDownload = ({ url, fileName }) => {

  const onDownload = () => {
    const link = document.createElement("a");
    link.download = fileName;
    link.href = url;
    link.click();
  }

  return (
    <Paper
      variant="outlined"
      sx={{
        padding:"16px",
        display:"flex"
      }}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item><h2>{fileName}</h2></Grid>
        <Grid item>
          <Button onClick={onDownload} variant="contained" >
            Download
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default FileDownload;
