import React from 'react';
import DateSettings from './subcomponents/settings_pannels/date_settings';
import TitleSettings from './subcomponents/settings_pannels/title_settings';
import AxisSettings from './subcomponents/settings_pannels/axis_settings';
import LegendSettings from './subcomponents/settings_pannels/legend_settings';
import HorizontalLineSettings from './subcomponents/settings_pannels/horizontal_line_settings';
import ChartSettings from './subcomponents/settings_pannels/chart_settings';
import { useFormContext } from '../contexts/FormContext';
import DynamicAccordionPanel from './subcomponents/DynamicAccordianPanel';
import { useSettingsManager } from '../hooks/useSettingsManager';


const SettingsPanel: React.FC = () => {
    const { form } = useFormContext();
    const { updateLinePlotSettings } = useSettingsManager();


    const components: JSX.Element[] = [
            <DateSettings onChange={updateLinePlotSettings} date_settings={form.settings.date_settings}/>,
            <TitleSettings onChange={updateLinePlotSettings} title_settings={form.settings.title_settings}/>,
            <ChartSettings onChange={updateLinePlotSettings} chart_settings={form.settings.chart_settings}/>,
            <AxisSettings onChange={updateLinePlotSettings} axis_settings={form.settings.axis_settings}/>,
            <LegendSettings onChange={updateLinePlotSettings} legend_settings={form.settings.legend_settings}/>,
            <HorizontalLineSettings onChange={updateLinePlotSettings} horizontal_line_settings={form.settings.horizontal_line_settings}/>,
        ]

    return (
        <DynamicAccordionPanel components={components} />
    );
};

export default SettingsPanel;