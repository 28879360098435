import React from 'react';
import { InputLabel, Select, MenuItem, Box, Divider, FormControlLabel, Switch, Typography } from '@mui/material';
import { BaseSettingsPanelProps } from './base_settings_pannel';
import { RAMTextField, RAMFormControl } from './base_settings';
import { SettingsFormType } from '../../../types/plotting_backend';

interface TitleSettingsProps extends BaseSettingsPanelProps {
    title_settings: SettingsFormType['title_settings']
}

const TitleSettings: React.FC<TitleSettingsProps> = ({title_settings, onChange}) => {

    const justificationOptions = [
        { label: 'Left', value: 0 },
        { label: 'Center', value: 0.5 },
        { label: 'Right', value: 1 },
    ];

    return (
        <div>
            <Box display="flex" flexDirection="column" gap="15px" paddingBottom="15px">
                <RAMTextField 
                    label={"Title"}
                    value={title_settings.title_text || ''}
                    onChange={(event) => onChange("title_settings", "title_text", event.target.value)}
                    size="small"
                />
                <RAMTextField 
                    label={"Title size"}
                    value={title_settings.title_font_size || ''}
                    onChange={(event) => onChange("title_settings", "title_font_size", event.target.value)}
                    size="small"
                />
                <RAMFormControl size="small">
                    <InputLabel>{"Title Justification"}</InputLabel>
                    <Select
                        value={title_settings.title_justify}
                        onChange={(event) => onChange("title_settings", "title_justify", event.target.value)}
                        label={"Title Justification"}
                    >
                        {justificationOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </RAMFormControl>
                <Divider />
            </Box>
            
            <Box display="flex" flexDirection="column" gap="15px" paddingBottom="15px">
                <RAMTextField 
                    label={"Subtitle"}
                    value={title_settings.subtitle_text || ''}
                    onChange={(event) => onChange("title_settings", "subtitle_text", event.target.value)}
                    size="small"
                />
                <RAMTextField 
                    label={"Subtitle size"}
                    value={title_settings.subtitle_font_size || ''}
                    onChange={(event) => onChange("title_settings", "subtitle_font_size", event.target.value)}
                    size="small"
                />
                <RAMFormControl size="small">
                    <InputLabel>{"Subtitle Justification"}</InputLabel>
                    <Select
                        value={title_settings.subtitle_justify}
                        onChange={(event) => onChange("title_settings", "subtitle_justify", event.target.value)}
                        label={"Subtitle Justification"}
                    >
                        {justificationOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </RAMFormControl>
                <FormControlLabel
                    sx={{px:1, width: "fit-content"}}
                    control={
                        <Switch
                            checked={title_settings.show_date_range}
                            onChange={(event) => onChange("title_settings", "show_date_range", event.target.checked)}
                            size="small"
                        />
                    }
                    label={<Typography sx={{fontSize: 12}} >Show Date Range</Typography>}
                />
                <Divider />
            </Box>

            <Box display="flex" flexDirection="column" gap="15px">
                <RAMTextField 
                    multiline={true}
                    label={"Disclaimer"}
                    value={title_settings.caption_text || ''}
                    onChange={(event) => onChange("title_settings", "caption_text", event.target.value)}
                    size="small"
                />
                <RAMTextField 
                    label={"Disclaimer size"}
                    value={title_settings.caption_font_size || ''}
                    onChange={(event) => onChange("title_settings", "caption_font_size", event.target.value)}
                    size="small"
                />
                <RAMFormControl size="small">
                    <InputLabel>{"Disclaimer Justification"}</InputLabel>
                    <Select
                        value={title_settings.caption_justify}
                        onChange={(event) => onChange("title_settings", "caption_justify", event.target.value)}
                        label={"Disclaimer Justification"}
                    >
                        {justificationOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </RAMFormControl>
                <RAMTextField 
                    label={"Disclaimer Width"}
                    value={title_settings.caption_width || ''}
                    onChange={(event) => onChange("title_settings", "caption_width", event.target.value)}
                    size="small"
                />
            </Box>
        </div>
    );
};

TitleSettings.displayName = 'Title Settings';
export default TitleSettings;