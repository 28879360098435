import React from 'react';
import { Button, List, ListItem, ListItemText, Divider, Typography, Paper, Box, Grid, Tabs } from '@mui/material';
import DocumentIconLarge from './DocumentIconLarge';
import RDoc from '../types/RDoc';



interface StartMenuProps {
  templates: RDoc[];
  drafts: RDoc[];
  onDocumentSelected: (document: RDoc) => void;
}

const styles = {
    container: {
      backgroundColor: "#212121",
      padding: "10px",
    },
    header: {
      padding: "10px",
      color: "white",
      fontSize: "9pt",
      fontWeight: 600,
    
      textDecoration: "underline",
      textDecorationColor: "#5f94c5",
      textUnderlineOffset: "6px",
    },
    gridContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '20px',
      padding: "10px",
    },
  };
  
  const StartMenu = ({ templates, drafts, onDocumentSelected  }: StartMenuProps) => {
    return (
        <Box sx={styles.container}>
            <Typography variant="h4" sx={{ ...styles.header, textDecoration: "none", fontSize: "16pt", marginBottom: "5px" }}>
                Select a place to start...
            </Typography>
            <Typography sx={styles.header}>
                TEMPLATES
            </Typography>
            <Grid container spacing={2} sx={styles.gridContainer}>
                {templates.map((template) => (
                    <Grid item key={template.id} onClick={() => onDocumentSelected(template)}>
                        <DocumentIconLarge template={template}/>
                    </Grid>
                ))}
            </Grid>
            <Typography variant="h6" sx={styles.header}>
                DRAFTS
            </Typography>
            <Grid container spacing={2} sx={styles.gridContainer}>
                {drafts.map((draft) => (
                    <Grid item key={draft.id} onClick={() => onDocumentSelected(draft)}>
                        <DocumentIconLarge template={draft}/>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};
  
export default StartMenu;